import Dropzone from "../../../../Dropzones/image"
import React, { useState } from "react"
import * as styles from "./index.module.less"
import OfficePDF from "../../../../Dropzones/OfficePDF"

const Assets = ({
  id,
  category = "office-images",
  images = [],
  pdf = null,
}) => {
  const [open, setOpen] = useState(false)
  const [activeItem, setAi] = useState(0)

  return (
    <div className={styles.assets}>
      <button className={"btn btn-link"} onClick={() => setOpen(!open)}>
        Assets
      </button>
      {open && (
        <AssetWrapper
          id={id}
          images={images}
          existingPdf={pdf}
          activeItem={activeItem}
          setAi={setAi}
          category={category}
        />
      )}
    </div>
  )
}

const AssetWrapper = ({
  id,
  images = [],
  existingPdf = null,
  setAi = () => null,
  activeItem = 0,
  category,
}) => {
  return (
    <>
      <ul className={styles.switcher_button_ul}>
        <li>
          <button
            className={`btn ${styles.switcher_button} ${
              activeItem === 0 ? styles.active : ""
            }`}
            onClick={() => setAi(0)}
          >
            Images
          </button>
        </li>
        <li>
          <button
            className={`btn ${styles.switcher_button} ${
              activeItem === 1 ? styles.active : ""
            }`}
            onClick={() => setAi(1)}
          >
            PDF
          </button>
        </li>
      </ul>

      <div className={activeItem === 0 ? "d-block" : "d-none"}>
        <Dropzone
          multiple={true}
          spDataId={id}
          category={category}
          existingImages={images}
          label={null}
          displayText={"Drop images here"}
        />
      </div>

      <div className={activeItem === 1 ? "d-block" : "d-none"}>
        <OfficePDF
          spDataId={id}
          category={"officeAsset"}
          existingPdf={existingPdf}
          label={null}
          displayText={"Drop file here"}
        />
      </div>
    </>
  )
}

export default Assets
