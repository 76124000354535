import React, { Fragment, useState } from "react"
import { Progress } from "reactstrap"
import Dropzone from "react-dropzone"
import { useAuth } from "../../../auth/useAuth"
import getOptimizedImageUrl from "../../../utils/getOptimizedImageUrl"
import { v4 as uuid } from "uuid"

import "./style.less"

const SingleImage = ({
  spaceId = null,
  name = null,
  existingImage = null,
  accept = null,
  circle = false,
  imgWidth = 300,
}) => {
  const { makeRequest } = useAuth()
  // const [files, setFiles] = useState([]);
  const [image, setImage] = useState(existingImage || false)
  const [progress, setProgress] = useState(false)

  const onDrop = (files) => {
    setProgress(true)
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const file = files[i]

        reader.onload = async (e) => {
          const fileNameArr = file.name.split(".")
          const ext = fileNameArr[fileNameArr.length - 1]

          const response = await makeRequest(
            "upload/single-image/get-signed-url",
            {
              key: `locations/${spaceId}/${uuid()}.${ext}`,
              type: file.type,
            }
          )

          let binary = atob(e.target.result.split(",")[1])
          let array = []
          // for each letter in the encrypted version, get binary value and push it to array
          for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }
          // use the array to construct a binary object (blob)
          let blobData = new Blob([new Uint8Array(array)], {
            type: file.type,
          })

          await fetch(response.uploadURL, {
            method: "PUT",
            body: blobData,
            mode: "cors",
          })

          // replace image
          await makeRequest("upload/single-image/replace-db-record", {
            spaceId: spaceId,
            name,
            imageUrl: response.Url,
          })

          setProgress(false)

          setImage(response.Url)
        }

        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <Fragment>
      <div className={"custom-dropzone-container d-inline-block"}>
        {image && (
          <img
            src={getOptimizedImageUrl(image, imgWidth)}
            alt=""
            className={`img-thumbnail uploader-single-image-preview ${
              circle && "circle-uploaded-image"
            }`}
          />
        )}
        <div className="dropzone-wrapper dropzone-wrapper-sm">
          <Dropzone
            disabled={progress}
            multiple={false}
            accept={accept}
            // to pass the category with the image
            onDrop={(files) => onDrop(files)}
            // onFileDialogCancel={this.onCancel.bind(this)}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`dropzone-custom-wrapper ${circle && "circle"}`}
              >
                <input {...getInputProps()} />
                <div className={`dropzone-content ${circle && "circle"}`}>
                  {progress ? (
                    <Progress animated bar value="100" />
                  ) : (
                    <p className="mb-0">Change</p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    </Fragment>
  )
}

export default SingleImage
