import React, { Fragment, useState } from "react"
import { Col, Label, Progress, Row } from "reactstrap"
import Dropzone from "react-dropzone"
import { useAuth } from "../../../auth/useAuth"
import { v4 as uuid } from "uuid"
// import { Link } from "reactstrap";
import "./style.less"

const OfficePDF = ({
  spDataId = null,
  category = "officeAsset",
  existingPdf = null,
  displayText = null,
  label = null,
  reload = () => null,
}) => {
  /**
   * values for category: respective image keys
   * space-plan-background  : jpg
   * space-plan-svg         : svg
   * hero-image             : jpg
   * logo                   : jpg
   * common-images          : jpg
   * building-images        : jpg
   * office-images          : jpg
   * desk-images            : jpg
   */
  displayText = displayText || "Drop file here"

  const { makeRequest } = useAuth()
  // const [files, setFiles] = useState([]);
  const [pdf, setPdf] = useState(existingPdf || false)
  const [progress, setProgress] = useState(false)

  const onDrop = (files, category) => {
    setProgress(true)
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const file = files[i]
        reader.readAsDataURL(file)
        reader.onload = async (e) => {
          const response = await makeRequest("upload/getSignedUrl", {
            spDataId,
            existingImage: pdf,
            category: category,
            key: category,
            filename: `${uuid()}.pdf`,
          })

          // turn image into binary
          // encodes image
          let binary = atob(e.target.result.split(",")[1])
          let array = []
          // for each letter in the encrypted version, get binary value and push it to array
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }
          // use the array to construct a binary object (blob)
          let blobData = new Blob([new Uint8Array(array)], {
            type: "application/pdf",
          })

          const result = await fetch(response.uploadURL, {
            method: "PUT",
            body: blobData,
            mode: "cors",
          })

          setProgress(false)

          setPdf(response.images)

          reload()
        }
      }
    }
  }

  const deletePDF = async (imageId, imageUrl) => {
    const response = await makeRequest("sp/delete-image", {
      imageId,
      spDataId,
      category,
      imageUrl,
    })
    setPdf(response.images)
  }

  return (
    <Fragment>
      <Row className="row-eq-height mb-4">
        {label && (
          <Col md="12">
            <Label for="">{label}</Label>
          </Col>
        )}
        <Col md={12}>
          <div className="dropzone-wrapper dropzone-wrapper-sm">
            <Dropzone
              disabled={progress}
              // to pass the category with the image
              onDrop={(files) => onDrop(files, category)}
              multiple={false}
              accept="application/pdf"
              // onFileDialogCancel={this.onCancel.bind(this)}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dropzone-content">
                    {progress ? (
                      <Progress animated bar value="100" />
                    ) : (
                      <p className="mb-0">{displayText}</p>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </Col>
        <Col md="12">
          {pdf && (
            <>
              <a
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-square btn btn-primary mt-2 btn-sm"
              >
                View existing file
              </a>
              {false && (
                <button
                  onClick={deletePDF}
                  className="btn-square btn btn-danger mt-2 btn-sm ml-2"
                >
                  Delete
                </button>
              )}
            </>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}

export default OfficePDF
