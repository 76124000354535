import React, { useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import { useAuth } from "../../../../../../auth/useAuth"
import Input from "../../../../fields/Input"
import Menu from "./Menu"

const RestaurantSection = ({
  data = {},
  setIsSaving = () => null,
  refresh = () => null,
}) => {
  const { makeRequest } = useAuth()

  const [rd, setRd] = useState(data?.restaurant_data || {})

  const save = async () => {
    setIsSaving(true)
    try {
      await makeRequest("sp/update", {
        id: data?.id,
        data: { restaurant_data: rd },
      })
      setIsSaving(false)
      toast.success("Details saved")
    } catch (e) {
      setIsSaving(false)
      toast.error("Failed to save the details")
    }
  }

  const setValue = (id, value) => {
    setRd({
      ...rd,
      [id]: value,
    })
  }
  return (
    <div>
      <Row>
        <Col sm={12} md={8} lg={6} className={"mt-3"}>
          <Input
            type="number"
            label="Booking time duration"
            name="bookingTimeDuration"
            helpText={""}
            value={rd?.bookingTimeDuration || ""}
            onChange={(value) => setValue("bookingTimeDuration", value)}
          />
          <Input
            type="text"
            label="Hours of Operation"
            name="hoursOfOperation"
            helpText={""}
            value={rd?.hoursOfOperation || ""}
            onChange={(value) => setValue("hoursOfOperation", value)}
          />
          <Input
            type="textarea"
            label="Location known for"
            name="locationKnownFor"
            placeholder="One per line"
            helpText={""}
            value={rd?.locationKnownFor || ""}
            onChange={(value) => setValue("locationKnownFor", value)}
          />
          <Input
            type="select"
            label="Internet Speed"
            name="internetSpeed"
            helpText={""}
            value={rd?.internetSpeed || ""}
            onChange={(value) => setValue("internetSpeed", value)}
          >
            <option value="">Please Select</option>
            {[
              "Fair (3-10 Mbps)",
              "Good (10-25 Mbps)",
              "Very Good (25-50 Mbps)",
              "Great (50-100 Mbps)",
              "Exceptional (100+ Mbps)",
            ].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Input>

          <Input
            type="select"
            label="Good for calls?"
            name="calls"
            helpText={""}
            value={rd?.calls || ""}
            onChange={(value) => setValue("calls", value)}
          >
            <option value="">Please Select</option>
            {[
              "Not Suitable",
              "Occasionally Noisy",
              "Background Buzz",
              "Relatively Quiet",
              "Quiet & Calm",
              "Dedicated Quiet Zones",
            ].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Input>

          <Input
            type="select"
            label="Noice Level"
            name="noiseLevel"
            helpText={""}
            value={rd?.noiseLevel || ""}
            onChange={(value) => setValue("noiseLevel", value)}
          >
            <option value="">Please Select</option>
            {["Quiet", "Moderate", "Lively", "Noisy"].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Input>

          <Input
            type="select"
            label="Outlets Available"
            name="plugs"
            helpText={""}
            value={rd?.plugs || ""}
            onChange={(value) => setValue("plugs", value)}
          >
            <option value="">Please Select</option>
            {["No Outlets", "Few Outlets", "Some Outlets", "Many Outlets"].map(
              (item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              )
            )}
          </Input>
          <Input
            type="textarea"
            label="Included with the booking"
            name="included_with_the_booking"
            placeholder="One per line"
            helpText={""}
            value={rd?.included_with_the_booking}
            onChange={(value) => setValue("included_with_the_booking", value)}
          />
        </Col>
        <Col sm={12} />

        {1 === 2 &&
          [1, 2, 3, 4, 5, 6, 7].map((number) => (
            <React.Fragment key={number}>
              <Col sm={12} md={4} lg={6} className={"mt-3"}>
                <Input
                  type="text"
                  label={`Menu ${number}: Label`}
                  name={`menu${number}Label`}
                  placeholder=""
                  helpText={""}
                  value={rd?.[`menu${number}Label`]}
                  onChange={(value) => setValue(`menu${number}Label`, value)}
                />
              </Col>
              <Col sm={12} md={8} lg={6} className={"mt-3"}>
                <Input
                  type="textarea"
                  label={`Menu ${number}: Items`}
                  name={`menu${number}Items`}
                  placeholder="One per line"
                  helpText={
                    "One per line. Separate the name, price and description using  a ="
                  }
                  value={rd?.[`menu${number}Items`]}
                  onChange={(value) => setValue(`menu${number}Items`, value)}
                />
              </Col>
            </React.Fragment>
          ))}

        <Col sm={12} md={8} lg={6} className={"mt-3"}>
          <Input
            type="text"
            label="WiFi Network Name"
            name="wifiName"
            helpText={""}
            value={rd?.wifiName}
            onChange={(value) => setValue("wifiName", value)}
          />
          <Input
            type="text"
            label="WiFi Password"
            name="wifiPassword"
            helpText={""}
            value={rd?.wifiPassword}
            onChange={(value) => setValue("wifiPassword", value)}
          />

          <Input
            type="textarea"
            label="House Rules"
            name="houseRules"
            placeholder="One per line"
            helpText={""}
            value={rd?.houseRules}
            onChange={(value) => setValue("houseRules", value)}
          />
        </Col>

        <Col sm={12} />

        <Col sm={4} md={3} lg={2} className={"mt-3"}>
          <Button color={"success"} size={"sm"} onClick={save}>
            Save
          </Button>
        </Col>
      </Row>

      <hr />

      <Menu data={data} refresh={refresh} />
    </div>
  )
}

export default RestaurantSection
