import React, { useState } from "react"

import { Button, Nav, TabContent, TabPane } from "reactstrap"
import Images from "./Sections/Images"
import Description from "./Sections/Description"
import Amenities from "./Sections/Amenities"
import Address from "./Sections/Address"

const tabs = [
  {
    name: "Images",
    slug: "images",
  },
  {
    name: "Location Description",
    slug: "description",
  },
  {
    name: "Amenities",
    slug: "amenities",
  },
  {
    name: "Address",
    slug: "address",
  },
]

const LocationDetails = ({
  id,
  formData,
  isSaving,
  setIsSaving,
  calendars = [],
  refresh = () => null,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].slug)

  return (
    <div>
      <Nav pills className="page-content-tabs">
        {tabs.map(({ name, slug }) => {
          return (
            <Button
              key={slug}
              color="default"
              className={`edit-space-tab-nav-link ${activeTab === slug &&
                "active"}`}
              onClick={() => setActiveTab(slug)}
            >
              {name}
            </Button>
          )
        })}
      </Nav>
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabs[0].slug}>
            <Images
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[1].slug}>
            <Description
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[2].slug}>
            <Amenities
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[3].slug}>
            <Address
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default LocationDetails
