import React, { useEffect, useState } from "react"

import {
  Button,
  Row,
  Col,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
} from "reactstrap"
import EmailConfirmations from "./Sections/EmailConfirmations"
import DayPasses from "./Sections/DayPasses"
import OutOfOfficeDates from "./Sections/OutOfOfficeDates"
import get from "lodash/get"
import MeetingRooms from "./Sections/MeetingRooms"
import Meetups from "./Sections/Meetups"
import OnlyAuthorized from "../../../../auth/OnlyAuthorized"

const tabs = [
  {
    name: "Out-of-Office",
    slug: "outOfOffice",
  },
  {
    name: "Day Passes",
    slug: "dayPasses",
  },
  {
    name: "Meeting Rooms",
    slug: "meetingRooms",
  },
  {
    name: "Email Confirmations",
    slug: "emailConfirmations",
  },
  {
    name: "Meetups",
    slug: "meetups",
    permission: "manage:space-providers",
  },
]

const MonthlyWorkspace = ({
  id,
  formData,
  isSaving,
  setIsSaving,
  calendars = [],
  refresh = () => null, // Can pass this to the meetup component. Whenever I send data to API, call this function
  commonHolidays = {},
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].slug)

  const config = formData?.spaceOperator?.meeting_rooms_config || {}

  const isRequestToBookMr =
    formData?.spaceOperator?.meeting_rooms_enabled &&
    config?.type === "request_to_book"

  const [selectedCommonHolidays, setSelectedCommonHolidays] = useState([])

  useEffect(() => {
    if (
      !commonHolidays ||
      commonHolidays.length === 0 ||
      !formData ||
      !formData.id
    ) {
      setSelectedCommonHolidays([])
      return
    }
    const city_from_location = get(formData, "office_address.city", null)
    const city = get(formData, "city", null)
    const spaceCity = city || city_from_location || ""
    if (!spaceCity) {
      setSelectedCommonHolidays([])
      return
    }
    const finalHolidays = commonHolidays[spaceCity]
    setSelectedCommonHolidays(finalHolidays)
  }, [commonHolidays, formData])

  return (
    <div>
      <Nav pills className="page-content-tabs">
        {tabs.map(({ name, slug, permission }) => {
          if (!isRequestToBookMr && slug === "meetingRooms") {
            return null
          }
          if (permission) {
            return (
              <OnlyAuthorized permission={permission} key={slug}>
                <Button
                  key={slug}
                  color="default"
                  className={`edit-space-tab-nav-link ${
                    activeTab === slug && "active"
                  }`}
                  onClick={() => setActiveTab(slug)}
                >
                  {name}
                </Button>
              </OnlyAuthorized>
            )
          }
          return (
            <Button
              key={slug}
              color="default"
              className={`edit-space-tab-nav-link ${
                activeTab === slug && "active"
              }`}
              onClick={() => setActiveTab(slug)}
            >
              {name}
            </Button>
          )
        })}
      </Nav>
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabs[0].slug}>
            <OutOfOfficeDates
              customHolidays={[]}
              space_id={formData.id}
              space={formData}
              commonHolidays={selectedCommonHolidays}
              // setCustomHolidays={customHolidays =>
              //   setValues({ customHolidays })
              // }
            />
          </TabPane>
          <TabPane tabId={tabs[1].slug}>
            <DayPasses
              id={formData.id}
              formData={formData}
              setIsSaving={setIsSaving}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[2].slug}>
            <MeetingRooms
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[3].slug}>
            <EmailConfirmations
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>

          <TabPane tabId={tabs[4].slug}>
            <Meetups id={id} refresh={refresh} formData={formData} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default MonthlyWorkspace
