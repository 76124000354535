import React from "react"
import { FormGroup, InputGroup, InputGroupAddon, Label } from "reactstrap"
import { FaCalendarAlt } from "react-icons/fa"
import DatePicker from "react-datepicker"
import "./datepicker.less"

const DatePickerInput = ({
  value,
  onChange,
  className = "",
  label,
  name,
  inline = false,
  withWeekends = false,
}) => {
  return (
    <FormGroup
      className={`${className} ${
        inline ? "d-flex align-items-center my-0" : ""
      } flex-nowrap`}
    >
      {label && (
        <Label
          for={name}
          className={inline ? "text-nowrap mr-2" : "mb-2 d-block"}
        >
          {label}
        </Label>
      )}
      <InputGroup
        className={`flex-nowrap ${withWeekends ? "" : "weekend-disabled"}`}
      >
        <InputGroupAddon addonType="prepend">
          <div className="input-group-text">
            <FaCalendarAlt />
          </div>
        </InputGroupAddon>
        <DatePicker
          className="form-control"
          selected={value}
          minDate={new Date()}
          onChange={(date) => onChange(date)}
          popperPlacement="bottom-end"
        />
      </InputGroup>
    </FormGroup>
  )
}

export default DatePickerInput
