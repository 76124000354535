import React, { useContext, useState } from "react"
import { Button, Nav, TabContent, TabPane } from "reactstrap"
import SpaceLayout from "./SpaceLayout"
import { SpaceContext } from "@hooks/SpaceContext"
import userHasAccess from "../../../../utils/userHasAccess"

import "../styles.less"

const tabs = [
  {
    name: "Private Offices - Dedicated Desks",
    slug: "floorPlans",
  },
]

const Availability = ({
  spaceId,
  floors = [],
  refresh = () => null,
  setIsSaving = () => null,
  formData = {},
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].slug)

  const {
    data: { soData },
  } = useContext(SpaceContext)

  return (
    <>
      {userHasAccess(soData, "day_passes_enabled") && (
        <Nav pills className="page-content-tabs">
          {tabs.map(({ name, slug, soVarName = null }) => {
            if (soVarName && !userHasAccess(soData, soVarName)) return null
            return (
              <Button
                key={slug}
                color="default"
                className={`edit-space-tab-nav-link ${
                  activeTab === slug && "active"
                }`}
                onClick={() => setActiveTab(slug)}
              >
                {name}
              </Button>
            )
          })}
        </Nav>
      )}
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabs[0].slug}>
            <SpaceLayout
              spaceId={spaceId}
              floors={floors}
              refresh={refresh}
              formData={formData}
            />
          </TabPane>
          {/*<TabPane tabId={tabs[1].slug}>*/}
          {/*  <DayPasses*/}
          {/*    id={spaceId}*/}
          {/*    formData={formData}*/}
          {/*    setIsSaving={setIsSaving}*/}
          {/*  />*/}
          {/*</TabPane>*/}
        </TabContent>
      </div>
    </>
  )
}

export default Availability
