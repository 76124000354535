import React, { useState } from "react"
import get from "lodash/get"
import Input from "../../fields/Input"
import Section from "../../fields/Section"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const OfficeRndId = ({ formData, refresh = () => null }) => {
  const { makeRequest } = useAuth()

  const [office_rnd_office_id, setOfficeRndOfficeId] = useState(
    get(formData, "office_rnd_office_id", "")
  )

  const handleUpdate = async () => {
    if (!formData.id) return
    const data = {
      id: formData.id,
      data: { office_rnd_office_id },
    }
    await makeRequest("sp/update", data)
      .then(() => null)
      .catch(() => null)
    toast.success("Updated Office RnD ID.")
    refresh()
  }

  return (
    <>
      <Section title={"Office RnD Data"}>
        <Row>
          <Col md="6" ld={5}>
            <Input
              type="text"
              label="ID from Office RnD"
              value={office_rnd_office_id}
              placeholder={""}
              onChange={(value) => setOfficeRndOfficeId(value)}
            />
          </Col>
          <Col md="12">
            <Button
              color={"success"}
              onClick={handleUpdate}
              disabled={!office_rnd_office_id}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default OfficeRndId
