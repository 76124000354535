import React, { useState } from "react"
import Section from "../../../../fields/Section"
import { Button, Col, Row } from "reactstrap"
import AddNewMeetingRoom from "./AddNew"
import { useAuth } from "../../../../../../auth/useAuth"

function MeetingRooms({ id, refresh = () => null, formData }) {
  const [activeMrId, setActiveMrId] = useState("")

  return (
    <React.Fragment>
      <Section title={"Meeting Rooms"} first>
        <Row>
          <Col lg="8">
            <AddNewMeetingRoom
              activeMrId={activeMrId}
              setActiveMrId={setActiveMrId}
              locationId={id}
              refresh={refresh}
              list={formData?.meetingRooms || []}
            />
            <ExistingMeetingRooms
              list={formData?.meetingRooms || []}
              edit={setActiveMrId}
              refresh={refresh}
            />
          </Col>
        </Row>
      </Section>
    </React.Fragment>
  )
}

export default MeetingRooms

const ExistingMeetingRooms = ({ list = [], edit, refresh = () => null }) => {
  const { makeRequest } = useAuth()

  const deleteMr = async (id) => {
    const response = window.confirm("Delete the meeting room permanently?")

    if (!response) return

    await makeRequest("admin/mr/delete", {
      id,
    })
    refresh(true) // Do a refresh like this
    // Fetches the profile again from the DB
  }

  if (!list || list.length === 0) {
    return null
  }
  return (
    <div className={"mt-5"}>
      <h4>Existing Meeting Rooms</h4>
      <table className="table mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Capacity</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map((mr) => (
            <tr key={mr?.id}>
              <td>{mr?.name}</td>
              <td>{mr?.capacity || 0}</td>
              <td className={"text-right"}>
                <Button
                  onClick={() => edit(mr?.id)}
                  color={"primary"}
                  size={"sm"}
                  className={"p-0"}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => deleteMr(mr?.id)}
                  color={"danger"}
                  size={"sm"}
                  className={"p-0 ml-2"}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
