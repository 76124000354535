import React, { useState } from "react"
import get from "lodash/get"
import Input from "../../fields/Input"
import Section from "../../fields/Section"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const Location = ({ formData, refresh = () => null }) => {
  const { makeRequest } = useAuth()

  const [timezone, setTimezone] = useState(get(formData, "timezone", ""))
  const [currency, setCurrency] = useState(get(formData, "currency", ""))

  const updateTac = async () => {
    if (!formData.id || !timezone || !currency) return
    const data = {
      id: formData.id,
      data: { timezone, currency },
    }
    const response = await makeRequest("sp/update", data)
    toast.success("Updated tax details.")
    refresh()
  }

  return (
    <>
      <Section title={"Location Details"}>
        <Row>
          <Col md="6">
            <label htmlFor="timezone">Time zone</label>
            <Input
              type="select"
              name="timezone"
              placeholder="# of months"
              value={timezone}
              onChange={setTimezone}
            >
              <option value="" disabled>
                Please select
              </option>
              <option value={"America/Toronto"}>America/Toronto</option>
              <option value="America/Vancouver">America/Vancouver</option>
              <option value="America/Edmonton">America/Edmonton</option>
              <option value="America/New_York">America/New_York</option>
            </Input>
          </Col>
          <Col md="6">
            <label htmlFor="timezone">Currency</label>
            <Input
              type="select"
              name="timezone"
              placeholder="# of months"
              value={currency}
              onChange={setCurrency}
            >
              <option value="" disabled>
                Please select
              </option>
              <option value={"cad"}>cad</option>
              <option value={"usd"}>usd</option>
            </Input>
          </Col>
          <Col md="12">
            <Button color={"success"} onClick={updateTac}>
              Update
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default Location
