import React, { forwardRef, useEffect, useRef, useState } from "react"
import { Button, Col, FormGroup, Row } from "reactstrap"
import Input from "../../../../fields/Input"
import Section from "../../../../fields/Section"
import Datepicker from "react-datepicker"
import { useAuth } from "../../../../../../auth/useAuth"
import * as styles from "./index.module.less"
import Bookings from "./_bookings"
import MeetupDropzone from "./MeetupDropzone"
import { format } from "date-fns"
import { setIn } from "formik"
import { getUtcDateTime } from "../../../../../../utils/getUtcDateTime"

const Meetups = ({ id, formData, refresh }) => {
  // Form
  const [imageUrl, setImageUrl] = useState("")
  const [meetupTitle, setMeetupTitle] = useState("")
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [host, setHost] = useState(null)
  const [hostPhotoUrl, setHostPhotoUrl] = useState(null)
  const [hostOrganization, setHostOrganization] = useState(null)
  const [community, setCommunity] = useState(null)
  const [invitationCode, setInvitationCode] = useState(null)

  const [formValid, setFormValid] = useState(true)
  const [formSuccess, setFormSuccess] = useState(false)

  // Upcoming & Past bookings tab
  const [type, setType] = useState(0)

  const [progress, setProgress] = useState(false)

  const { makeRequest } = useAuth()

  const clear = () => {
    setImageUrl("")
    setDescription("")
    setMeetupTitle("")
    setDate("")
    setStartTime("")
    setEndTime("")
    setHost(null)
    setHostPhotoUrl(null)
    setCommunity(null)
    setHostOrganization(null)
    setInvitationCode(null)
  }

  const tz = formData?.timezone || "America/Toronto"

  const isDisabled =
    !imageUrl ||
    !meetupTitle ||
    !date ||
    !startTime ||
    !endTime ||
    !meetupTitle?.trim()
  // Event Handlers
  const handleSubmit = async () => {
    // Form default state
    setFormSuccess(false)

    // Form Validation
    if (isDisabled) {
      setFormValid(false)
      return
    }

    setProgress(true)

    const body = {
      image: imageUrl,
      title: meetupTitle?.trim(),
      // description: descRef.current.getContent(),
      description,
      date: format(date, "yyyy-MM-dd"),
      startTime: startTime,
      endTime: endTime,
      startDateTime: getUtcDateTime(date, startTime, tz),
      endDateTime: getUtcDateTime(date, endTime, tz),
      spaceId: id,
      type: community,
      hostOrganization,
      hostPhotoUrl,
      invitationCode,
      host,
    }

    // @Mahesh, how do I check if this was successful
    const res = await makeRequest("meetup", body)

    if (res.message === "Success") {
      setFormValid(true)
      setFormSuccess(true)
      refresh(true)
    }

    if (process.env.NODE_ENV === "production") {
      clear()
    }

    setProgress(false)
  }

  useEffect(() => {
    if (formSuccess) {
      setTimeout(() => {
        setFormSuccess(!formSuccess)
      }, 5000)
    }
  }, [formSuccess])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input className="form-control" onClick={onClick} ref={ref}>
      {value}
    </input>
  ))

  return (
    <Section title={"Co-work meetup events"} first>
      <Row>
        <Col lg="6">
          <label>Feature Image</label>
          <MeetupDropzone
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            makeRequest={makeRequest}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Input
            label={"Meetup Title"}
            name="meetuptitle"
            helpText={""}
            value={meetupTitle}
            onChange={(text) => setMeetupTitle(text)}
          />
          <label>Meetup Description</label>
          <textarea
            rows="10"
            className={"form-control"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="mt-3">
          <FormGroup>
            <label htmlFor="">Date</label>
            <Datepicker
              inline={false}
              dateFormat="dd MMM, yyyy"
              selected={date}
              minDate={new Date()}
              onSelect={(date) => setDate(date)}
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <label className={"mb-3 mr-2"}>Start & End Time</label>
        </Col>
        <Col lg={3}>
          <Input
            type="select"
            name="officeTermCommitment"
            placeholder="# of months"
            value={startTime}
            onChange={setStartTime}
          >
            <option value="" disabled>
              Please select
            </option>
            <option>9:00 AM</option>
            <option>9:30 AM</option>
            <option>10:00 AM</option>
            <option>10:30 AM</option>
            <option>11:00 AM</option>
            <option>11:30 AM</option>
            <option>12:00 PM</option>
            <option>12:30 PM</option>
            <option>01:00 PM</option>
            <option>01:30 PM</option>
            <option>02:00 PM</option>
            <option>02:30 PM</option>
            <option>03:00 PM</option>
            <option>3:30 PM</option>
            <option>4:00 PM</option>
          </Input>
        </Col>
        {/*<p className={"mb-3 mx-2"}>–</p>*/}
        <Col lg={3}>
          <Input
            type="select"
            name="dedicatedDesksTermCommitment"
            placeholder="# of months"
            value={endTime}
            onChange={setEndTime}
          >
            <option value="" disabled>
              Please select
            </option>
            <option>10:00 AM</option>
            <option>10:30 AM</option>
            <option>11:00 AM</option>
            <option>11:30 AM</option>
            <option>12:00 PM</option>
            <option>12:30 PM</option>
            <option>1:00 PM</option>
            <option>1:30 PM</option>
            <option>2:00 PM</option>
            <option>2:30 PM</option>
            <option>3:00 PM</option>
            <option>3:30 PM</option>
            <option>4:00 PM</option>
            <option>4:30 PM</option>
            <option>5:00 PM</option>
            <option>5:30 PM</option>
            <option>6:00 PM</option>
          </Input>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <label>Host Photo URL</label>
          <MeetupDropzone
            imageUrl={hostPhotoUrl}
            setImageUrl={setHostPhotoUrl}
            makeRequest={makeRequest}
            options={{
              maxHeight: 512,
              maxWidth: 512,
            }}
          />
          <Input
            label={"Host"}
            name="host"
            helpText={""}
            value={host}
            onChange={(value) => setHost(value)}
          />
          <Input
            label={"Host Organization"}
            name="hostOrganization"
            helpText={""}
            value={hostOrganization}
            onChange={(value) => setHostOrganization(value)}
          />
          <Input
            label={"Type of meetup"}
            name="type"
            helpText={""}
            value={community}
            onChange={(value) => setCommunity(value)}
          />{" "}
          <Input
            label={"Invitation Code"}
            name="invitationCode"
            helpText={""}
            value={invitationCode}
            onChange={(value) => setInvitationCode(value)}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12} className={"mt-3"}>
          {!formValid && (
            <p className="text-danger">
              Form contains invalid fields, please double check
            </p>
          )}
          {formSuccess && <p className="text-success">Meetup created</p>}
        </Col>
        <Col sm={4} md={3} lg={2}>
          <Button
            // disabled={true}
            color={"primary"}
            size={"sm"}
            onClick={handleSubmit}
            disabled={isDisabled || progress}
          >
            Save
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Col className="my-5 w-100 border-top border-dark" lg={12}></Col>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <div>
            <p
              className={`${styles.tab} mr-4 ${
                type === 0 ? styles.active : "text-muted"
              }`}
              onClick={() => setType(0)}
            >
              Upcoming
            </p>
            <p
              className={`${styles.tab} ${
                type === 1 ? styles.active : "text-muted"
              }`}
              onClick={() => setType(1)}
            >
              Past
            </p>
          </div>
          <Bookings
            formData={formData}
            makeRequest={makeRequest}
            pastBookings={type !== 0}
            refresh={refresh}
          />
        </Col>
      </Row>
    </Section>
  )
}

export default Meetups
