import React, { useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"
import OfficeDeskDetails from "./OfficeDeskDetails"
import prepareSvgBlock from "./prepareSvgBlock"
import {
  DEDICATED_DESK,
  getIdentifierFromSvg,
  innerElements,
  OFFICE,
} from "./utils"
import SVG from "react-inlinesvg"

import "./styles.less"

const SpaceLayout = ({
  svg,
  background,
  offices = [],
  dedicatedDesks = [],
  floorId,
  refresh,
  setVisibility,
  formData = {},
}) => {
  const { makeRequest } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [, setSvgs] = useState()
  const [svgId, setCurrentSvgId] = useState()
  const toggleModal = () =>
    isModalOpen ? setModalOpen(false) : setModalOpen(true)

  const getOfficeDeskBySvgId = (svgId) => {
    if (!svgId) {
      return false
    }
    const office = offices.find((office) => office.svg_identifier === svgId)
    if (office) {
      office.type = OFFICE
      return office
    }
    const dedicatedDesk = dedicatedDesks.find(
      (dd) => dd.svg_identifier === svgId
    )

    if (dedicatedDesk) {
      dedicatedDesk.type = DEDICATED_DESK
      return dedicatedDesk
    }
    return false
  }

  const [validDesks, setValidDesks] = useState([])
  const [validOffices, setValidOffices] = useState([])

  const setValidSpaces = (svgId) => {
    dedicatedDesks.forEach((elem) => {
      if (elem.svg_identifier === svgId) {
        let temp = validDesks
        temp.push(elem.id)
        setValidDesks(temp)
      }
    })
    offices.forEach((elem) => {
      if (elem.svg_identifier === svgId) {
        let temp = validOffices
        temp.push(elem.id)
        setValidOffices(temp)
      }
    })
  }

  const onClick = (e) => {
    const svgId = getIdentifierFromSvg(e.target)
    setCurrentSvgId(svgId)
    setModalOpen(true)
  }

  const [loaded, setLoaded] = useState(0)

  const setVisibilityForImages = (image = false) => {
    setLoaded(loaded + 1)
    if (image) {
      setVisibility(true)
    }
  }

  useEffect(() => {
    if (loaded >= 2) {
      // when both images are loaded, continue to filter the locations

      // if valid locations are less than available locations, delete the additional ones
      if (validDesks.length < dedicatedDesks.length) {
        dedicatedDesks.forEach((space) => {
          const valid = validDesks.find((elem) => elem === space.id)

          if (!valid) {
            deleteSpace(space.id, "dedicated_desks")
          }
        })
      }
      if (validOffices.length < offices.length) {
        offices.forEach((space) => {
          const valid = validOffices.find((elem) => elem === space.id)

          if (!valid) {
            deleteSpace(space.id, "offices")
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  const deleteSpace = (id, type) => {
    if (!id || !type) {
      return
    }
    makeRequest("sp/delete-space-details/resetSpaceBlockDetails", {
      id,
      type,
    })
      .then(() => null)
      .catch(() => null)
  }

  return (
    <div
      className={"space-layout-container"}
      id={"space-layout-container-" + floorId}
    >
      <SVG
        src={`${
          process.env.GATSBY_API_URL
        }/upload/getSvg?url=${encodeURIComponent(svg)}`}
        onLoad={() => {
          const indexedSvgs = {}
          const rootSvg = document.querySelector(
            `#space-layout-container-${floorId} svg`
          )
          rootSvg.removeAttribute("width")
          rootSvg.removeAttribute("height")
          document
            .querySelectorAll(innerElements(floorId))
            .forEach((element) => {
              const svgId = getIdentifierFromSvg(element)
              indexedSvgs[svgId] = element
              setVisibilityForImages()

              const selectedOfficeOrDesk = getOfficeDeskBySvgId(svgId)

              setValidSpaces(svgId)
              element.addEventListener("click", onClick)

              prepareSvgBlock(element, selectedOfficeOrDesk)
            })
          setSvgs(indexedSvgs)
        }}
      />
      <img
        src={background}
        className={"space-layout-background"}
        alt=""
        onLoad={() => setVisibilityForImages(true)}
      />
      <OfficeDeskDetails
        refresh={refresh}
        svgId={svgId}
        isOpen={isModalOpen}
        toggle={toggleModal}
        floorId={floorId}
        slugForProfileGenerator={formData.slug || formData.id}
        data={getOfficeDeskBySvgId(svgId)}
        space_id={formData.id}
      />
    </div>
  )
}

export default SpaceLayout
