import React, { useState } from "react"
import Section from "../../../../fields/Section"
import GooglePlacesSearchBox from "../../../../fields/GooglePlacesSearchBox"
import { useAuth } from "@auth/useAuth"
import OnlyAuthorized from "@auth/OnlyAuthorized"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const cities = [
  {
    label: "Toronto",
    value: "Toronto",
  },
  { label: "Kitchener-Waterloo", value: "Kitchener" },
  {
    label: "Hamilton",
    value: "Hamilton",
  },
  { label: "Ottawa", value: "Ottawa" },
  { label: "Calgary", value: "Calgary" },
  { label: "Montreal", value: "Montreal" },
  { label: "Kelowna", value: "Kelowna" },
  { label: "New York", value: "New York" },
  { label: "Boston", value: "Boston" },
  { label: "Vancouver", value: "Vancouver" },
]

const Address = ({
  id,
  formData,
  isSaving,
  setIsSaving,
  refresh = () => null,
}) => {
  const { makeRequest, hasScope } = useAuth()

  const [city, setCity] = useState(formData.city || "")

  const [officeAddress, setOfficeAddress] = useState(
    formData.office_address || ""
  )

  const handleCustomStreetView = (value) => {
    setOfficeAddress({
      ...officeAddress,
      customStreetView: value,
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!officeAddress) return
    setIsSaving(true)
    await makeRequest("sp/edit", {
      id,
      custom: true,
      data: { office_address: JSON.stringify(officeAddress), city },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Section title={"Address"} first>
          <GooglePlacesSearchBox
            value={officeAddress}
            onChange={setOfficeAddress}
            onCustomStreetView={handleCustomStreetView}
          />
          {officeAddress && officeAddress.city && (
            <OnlyAuthorized permission={"manage:space-providers"}>
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="customCity">City for on-demand spaces</label>
                  <p className={"m-0"}>
                    This space will be listed for {officeAddress.city}. If it
                    needs to be changed, please add the correct city below.
                  </p>
                  <select
                    className="form-control"
                    id={"customCity"}
                    name="customCity"
                    placeholder=""
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option value="">Please Select</option>
                    {cities.map(({ value }) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </OnlyAuthorized>
          )}
        </Section>

        <Row>
          <Col lg="12">
            <Button
              color="success"
              size="lg"
              type="submit"
              className="btn-square"
              disabled={isSaving}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  )
}

export default Address
