import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input as ReactStrapInput,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { v4 as uuid } from "uuid"
import Input from "../../../../fields/Input"
import StyledInput from "../../../../fields/Input"
import { useAuth } from "../../../../../../auth/useAuth"
import createImage from "./createImage"

const AddNewMeetingRoom = ({
  activeMrId,
  setActiveMrId = () => null,
  list = [],
  locationId,
  refresh = () => null,
}) => {
  const [data, setData] = useState({})
  const [progress, setProgress] = useState(false)

  const [image, setImage] = useState("")

  const [imageProgress, setImageProgress] = useState(false)

  const { makeRequest } = useAuth()

  useEffect(() => {
    if (!activeMrId) {
      return
    }
    const existingData = list.find((item) => item.id === activeMrId)
    if (existingData) {
      setData({
        ...existingData,
        isExisting: true,
      })
      setImage(existingData?.cover_image_url || "")
    } else {
      setData({})
      setImage("")
    }
  }, [activeMrId])

  const save = async () => {
    setProgress(true)
    if (data.isExisting) {
      // update
      const newData = { ...data }
      delete newData.id
      delete newData.created_at
      delete newData.updated_at
      delete newData.isExisting
      await makeRequest("admin/mr/update", {
        id: data?.id,
        data: newData,
      })
      setProgress(false)
      refresh(true)
      setActiveMrId("")
      return
    }

    await makeRequest("admin/mr/add", {
      ...data,
      space_id: locationId,
    })
    setProgress(false)
    refresh(true)
    setActiveMrId("")
  }

  const updateData = (key, value) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  useEffect(() => {
    if (!image) return
    updateData("cover_image_url", image)
  }, [image])

  const onDrop = (files) => {
    setImageProgress(true)
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const file = files[i]
        reader.readAsDataURL(file)
        reader.onload = async (e) => {
          const response = await makeRequest(
            "upload/single-image/get-signed-url",
            {
              key: `locations/${uuid()}.jpg`,
              type: "image/jpeg",
            }
          )

          const mainImage = await createImage(file)

          const blobDataForMain = await fetch(mainImage).then((res) =>
            res.blob()
          )

          await fetch(response.uploadURL, {
            method: "PUT",
            body: blobDataForMain,
            mode: "cors",
          })

          setImageProgress(false)

          setImage(response.Url)
        }
      }
    }
  }

  return (
    <div>
      <Button color={"primary"} size="sm" onClick={() => setActiveMrId(uuid())}>
        Add Meeting Room
      </Button>
      <Modal isOpen={!!activeMrId} toggle={() => setActiveMrId(null)} centered>
        <ModalHeader toggle={() => setActiveMrId(false)}>
          {data.created_at
            ? "Update Meeting Room Details"
            : "Add New Meeting Room"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="row">
                <div className="col-sm-6 d-flex align-items-center">
                  <div>
                    <strong>Main Image</strong>
                    <br />
                    <span>This is the image shown as the thumbnail</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    style={{
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${
                        image || data?.cover_image_url || ""
                      })`,
                    }}
                  >
                    <Dropzone
                      disabled={imageProgress}
                      multiple={false}
                      accept={""}
                      // to pass the category with the image
                      onDrop={(files) => onDrop(files)}
                      // onFileDialogCancel={this.onCancel.bind(this)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className={`dropzone-custom-wrapper `}
                        >
                          <input {...getInputProps()} />
                          <div className={`dropzone-content`}>
                            {imageProgress ? (
                              <Progress
                                animated
                                bar
                                value="100"
                                style={{ height: "8px" }}
                              />
                            ) : (
                              <p className="mb-0">Drag / Click to select</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <Input
                type="text"
                label={"Meeting room name"}
                name="mrName"
                placeholder="Meeting room name"
                value={data?.name || ""}
                onChange={(value) => updateData("name", value)}
              />
            </Col>
            <Col xs={12}>
              <Input
                type="number"
                label={"Capacity"}
                name="capacity"
                placeholder="Capacity"
                value={data?.capacity || ""}
                onChange={(value) => updateData("capacity", value)}
              />
            </Col>
            <Col xs={6}>
              <label htmlFor="" className="form-label">
                Price per day
              </label>
              <InputGroup>
                <InputGroupAddon addonType="prepend" className={"ml-0"}>
                  $
                </InputGroupAddon>
                <ReactStrapInput
                  min={0}
                  type="number"
                  step="1"
                  value={data?.price_per_day || ""}
                  onChange={(e) => updateData("price_per_day", e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <label htmlFor="" className="form-label">
                Price per hour
              </label>
              <InputGroup>
                <InputGroupAddon addonType="prepend" className={"ml-0"}>
                  $
                </InputGroupAddon>
                <ReactStrapInput
                  min={0}
                  type="number"
                  step="1"
                  value={data?.price_per_hour || ""}
                  onChange={(e) => updateData("price_per_hour", e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12}>
              <StyledInput
                label="Amenities"
                name="amenities"
                placeholder={"One item per line"}
                type="textarea"
                className={"mb-0 mt-3"}
                value={data?.amenities || ""}
                onChange={(value) => updateData("amenities", value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={
              progress ||
              !data?.name ||
              !data?.cover_image_url ||
              !data?.capacity
            }
            onClick={save}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AddNewMeetingRoom
