import React, { useState } from "react"
import Section from "../../../../fields/Section"
import Input from "../../../../fields/Input"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const Terms = ({ id, formData, isSaving, setIsSaving, calendars = [] }) => {
  const { makeRequest, hasScope } = useAuth()

  const [officeTermCommitment, setOfficeTermCommitment] = useState(
    formData.office_term_commitment || "Month to Month"
  )
  const [dedicatedDesksTermCommitment, setDedicatedDesksTermCommitment] =
    useState(formData.dedicated_desks_term_commitment || "Month to Month")

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        office_term_commitment: officeTermCommitment,
        dedicated_desks_term_commitment: dedicatedDesksTermCommitment,
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Section title={"Terms"} first>
          <Row>
            <Col lg="6">
              <Input
                type="select"
                label={"Minimum office term commitment"}
                name="officeTermCommitment"
                placeholder="# of months"
                value={officeTermCommitment}
                onChange={setOfficeTermCommitment}
              >
                <option value="" disabled>
                  Please select
                </option>
                <option value="Month to Month">
                  No Commitment: Month to Month
                </option>
                <option>2 Months</option>
                <option>3 Months</option>
                <option>6 Months</option>
                <option>12 Months</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Input
                type="select"
                label="Minimum dedicated desks term commitment"
                name="dedicatedDesksTermCommitment"
                placeholder="# of months"
                value={dedicatedDesksTermCommitment}
                onChange={setDedicatedDesksTermCommitment}
              >
                <option value="" disabled>
                  Please select
                </option>
                <option value="Month to Month">
                  No Commitment: Month to Month
                </option>
                <option>2 Months</option>
                <option>3 Months</option>
                <option>6 Months</option>
                <option>12 Months</option>
              </Input>
            </Col>
          </Row>
        </Section>

        <Row>
          <Col lg="12">
            <Button
              color="success"
              size="lg"
              type="submit"
              className="btn-square"
              disabled={isSaving}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  )
}

export default Terms
