import React, { useState } from "react"
import Section from "../../../../fields/Section"
import Input from "../../../../fields/Input"
import RadioBox from "../../../../fields/RadioSwitch"
import CounterInput from "../../../../fields/CounterInput"
import AdditionalIncludes from "../../../../fields/AdditionalIncludes"
import { useAuth } from "@auth/useAuth"
// import NotAuthorized from "@auth/NotAuthorized"
// import OnlyAuthorized from "@auth/OnlyAuthorized"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const Amenities = ({ id, formData, isSaving, setIsSaving, calendars = [] }) => {
  const { makeRequest, hasScope } = useAuth()

  const [furnished, setFurnished] = useState(formData.furnished || false)
  const [highSpeedInternet, setHighSpeedInternet] = useState(
    formData.high_speed_internet || false
  )
  const [petFriendly, setPetFriendly] = useState(formData.pet_friendly || false)

  const [fullKitchenAccess, setFullKitchenAccess] = useState(
    formData.full_kitchen_access || false
  )
  const [coffee, setCoffee] = useState(formData.coffee || false)
  const [printing, setPrinting] = useState(formData.printing || false)
  const [commonAreas, setCommonAreas] = useState(formData.common_areas || false)
  const [mailBox, setMailBox] = useState(formData.mail_box || false)

  const [meetingRooms, setMeetingRooms] = useState(formData.meeting_rooms || 0)

  const [phoneBooths, setPhoneBooths] = useState(formData.phone_booths || 0)

  const [additionalIncludes, setAdditionalIncludes] = useState(
    formData.additional_includes || []
  )

  const [officeAccessCopy, setOfficeAccessCopy] = useState(
    formData.office_access_copy || ""
  )

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        high_speed_internet: highSpeedInternet,
        furnished,
        pet_friendly: petFriendly,
        full_kitchen_access: fullKitchenAccess,
        coffee,
        phone_booths: phoneBooths,
        ergonomic_chairs: false,
        printing,
        common_areas: commonAreas,
        mail_box: mailBox,
        meeting_rooms: meetingRooms,
        office_access_copy: officeAccessCopy,
        additional_includes: JSON.stringify(additionalIncludes),
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Section title={"Amenities"} first>
          <Row>
            <Col lg="6">
              <CounterInput
                label="Number of meeting rooms"
                name="meetingRooms"
                min={0}
                max={100}
                value={meetingRooms}
                onChange={setMeetingRooms}
                className=""
              />
              <CounterInput
                label="Number of phone booths"
                name="phoneBooths"
                min={0}
                max={100}
                value={phoneBooths}
                onChange={setPhoneBooths}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <RadioBox
                label="High speed internet"
                onChange={(val) => setHighSpeedInternet(val)}
                name="highSpeedInternet"
                value={highSpeedInternet}
              />

              <RadioBox
                label="Furnished"
                onChange={(val) => setFurnished(val)}
                name="furnished"
                value={furnished}
              />

              <RadioBox
                label="Full Kitchen Access"
                onChange={(val) => setFullKitchenAccess(val)}
                name="fullKitchenAccess"
                value={fullKitchenAccess}
              />

              <RadioBox
                label="Coffee"
                onChange={(val) => setCoffee(val)}
                name="coffee"
                value={coffee}
              />

              <RadioBox
                label="Printing"
                onChange={(val) => setPrinting(val)}
                name="printing"
                value={printing}
              />

              <RadioBox
                label="Common Areas"
                onChange={(val) => setCommonAreas(val)}
                name="commonAreas"
                value={commonAreas}
              />

              <RadioBox
                label="Mail Box"
                onChange={(val) => setMailBox(val)}
                name="mailBox"
                value={mailBox}
              />

              <RadioBox
                label="Pet Friendly"
                onChange={(val) => setPetFriendly(val)}
                name="petFriendly"
                value={petFriendly}
              />

              <Input
                className=""
                type="text"
                label="Office Access Copy"
                name="officeAccessCopy"
                placeholder="E.g. Access to the office 24/7"
                value={officeAccessCopy}
                onChange={setOfficeAccessCopy}
              />

              {/*<CounterInput*/}
              {/*  label="Number of breakout rooms"*/}
              {/*  helpText="Meeting rooms that don’t require booking"*/}
              {/*  name="breakfastRooms"*/}
              {/*  min={0}*/}
              {/*  max={100}*/}
              {/*  value={breakoutRooms}*/}
              {/*  onChange={setBreakoutRooms}*/}
              {/*/>*/}
            </Col>
          </Row>
          <Row>
            <Col lg="8">
              <h5 className={"mt-3"}>Additional amenities (Optional)</h5>
              <AdditionalIncludes
                values={additionalIncludes}
                handleChange={setAdditionalIncludes}
              />
            </Col>
          </Row>
        </Section>

        <Row>
          <Col lg="12">
            <Button
              color="success"
              size="lg"
              type="submit"
              className="btn-square"
              disabled={isSaving}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  )
}

export default Amenities
