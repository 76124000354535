import React, { useState } from "react"
import Section from "../../../../fields/Section"
import Input from "../../../../fields/Input"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const Description = ({ id, formData, isSaving, setIsSaving }) => {
  const { makeRequest } = useAuth()

  const [companyName, setCompanyName] = useState(formData.space_name || "")

  const [spaceTitle, setSpaceTitle] = useState(formData.space_title || "")
  const [spaceDescription, setSpaceDescription] = useState(
    formData.space_description || ""
  )

  const onSubmit = (e) => {
    e.preventDefault()
    if (!companyName || !spaceTitle || !spaceDescription) {
      toast.error("All the fields are required")
      return
    }
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        space_name: companyName,
        space_title: spaceTitle,
        space_description: spaceDescription,
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Section title={"Description"} first>
          <Row>
            <Col lg="8">
              <Input
                type="text"
                label={"Location name"}
                name="companyName"
                placeholder="Company Name"
                value={companyName}
                onChange={setCompanyName}
              />
              <Input
                type="text"
                label="Location Title"
                name="spaceName"
                placeholder="Listing title"
                value={spaceTitle}
                onChange={setSpaceTitle}
              />
              <Input
                type="textarea"
                label="Describe your place"
                name="spaceDetails"
                placeholder="Describe what makes your place especial"
                value={spaceDescription}
                onChange={setSpaceDescription}
              />
            </Col>
          </Row>
        </Section>

        <Row>
          <Col lg="12">
            <Button
              color="success"
              size="lg"
              type="submit"
              className="btn-square"
              disabled={isSaving}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  )
}

export default Description
