import React, { useState } from "react"
import Section from "../../../../fields/Section"
import { useAuth } from "@auth/useAuth"
import {
  Button,
  Col,
  Input as ReactStrapInput,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import RadioBox from "../../../../fields/RadioSwitch"
import get from "lodash/get"
import RestaurantSection from "./RestaurantSection"
import isRestaurant from "../../../../../../utils/isRestaurant"

const DayPasses = ({ id, formData, setIsSaving, refresh }) => {
  const { makeRequest } = useAuth()

  const [dayPasses, setDayPasses] = useState(
    !!get(formData, "price_per_day_pass", false)
  )

  const [dayPassesPerDay, setDayPassesPerDay] = useState(
    get(formData, "day_passes_per_day", 0)
  )

  const [price, setPrice] = useState(
    get(formData, "price_per_day_pass", 0) || 0
  )

  const save = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    const data = {
      price_per_day_pass: dayPasses ? Number(price) : 0,
      day_passes_per_day: dayPasses ? dayPassesPerDay : 0,
    }
    try {
      await makeRequest("sp/update", { id, data })
      setIsSaving(false)
      toast.success("Details saved")
    } catch (e) {
      setIsSaving(false)
      toast.error("Failed to save the details")
    }
  }

  return (
    <React.Fragment>
      <Section title={"Day Passes"} first>
        <Row>
          <Col lg="8">
            <RadioBox
              label="Do you offer day passes at this location? "
              onChange={(value) => {
                setDayPasses(value)
              }}
              name="coffee"
              value={dayPasses || false}
            />
          </Col>
        </Row>
        {dayPasses && (
          <>
            <Row>
              <Col sm={4} md={3} lg={2} className={"mt-3"}>
                <label htmlFor="" className="form-label">
                  Price per pass
                </label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend" className={"ml-0"}>
                    $
                  </InputGroupAddon>
                  <ReactStrapInput
                    min={0}
                    type="number"
                    step="1"
                    value={price || ""}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className={"mt-4"}>
                <label htmlFor="" className="form-label">
                  Day passes limit per day <br />
                  <small>
                    (Keep the field empty or '0' to remove the limit.)
                  </small>
                </label>
              </Col>
              <Col sm={4} md={3} lg={2} className={"mt-1"}>
                <InputGroup>
                  <ReactStrapInput
                    type="number"
                    step="1"
                    value={dayPassesPerDay || ""}
                    onChange={(e) => setDayPassesPerDay(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col sm={4} md={3} lg={2} className={"mt-3"}>
            <Button color={"success"} size={"sm"} onClick={save}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      {isRestaurant(formData) && (
        <Section title={"Restaurant Data"}>
          <RestaurantSection
            data={formData}
            setIsSaving={setIsSaving}
            refresh={refresh}
          />
        </Section>
      )}
    </React.Fragment>
  )
}

export default DayPasses
