import React, { useState } from "react"
import Input from "../../fields/Input"
import Section from "../../fields/Section"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import RadioBox from "../../fields/RadioSwitch"

const RetryPayment = ({ formData, refresh = () => null, categories }) => {
  const { makeRequest } = useAuth()

  const [progress, setProgress] = useState(false)

  const handleRetry = async (value) => {
    if (!formData.id) return
    setProgress(true)
    const data = {
      id: formData.id,
      data: { retry_payment_collection: value },
    }
    await makeRequest("sp/update", data)
      .then(() => null)
      .catch(() => null)
    toast.success("Changed payment retry status")

    setProgress(false)
    refresh()
  }

  return (
    <>
      <Section title={"Retry Payment"}>
        <Row>
          <Col lg="6">
            <RadioBox
              label="Retry payment?"
              onChange={handleRetry}
              name="coffee"
              value={formData?.retry_payment_collection || false}
              disabled={progress}
            />
            <p className={"m-0"}>Current setup:</p>
            {!formData?.retry_payment_collection ? (
              <p>
                - The booking will be marked as "Paid"
                <br /> - The booking will be flagged as a "Manual Payment"
                <br /> - SO will receive a notification email
              </p>
            ) : (
              <>
                <p>- The API will continue trying to collect the payment.</p>
              </>
            )}
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default RetryPayment
