import React, { useContext, useEffect, useState } from "react"

import { Button, Nav, TabContent, TabPane } from "reactstrap"
import Terms from "./Sections/Terms"
import WorkspaceDescription from "./Sections/WorkspaceDescription"
import ContactPerson from "./Sections/ContactPerson"
import EmailConfirmations from "./Sections/EmailConfirmations"
import InventoryAnalytics from "../../../../components/Tabs/InventoryAnalytics"
import { SpaceContext } from "@hooks/SpaceContext"

const tabs = [
  {
    name: "Terms",
    slug: "terms",
  },
  {
    name: "Workspace Description",
    slug: "workspaceDescription",
  },
  {
    name: "Contact Person",
    slug: "contactPerson",
  },
  {
    name: "Email Confirmations",
    slug: "emailConfirmations",
  },
  {
    name: "Reporting",
    slug: "reporting",
    dashboard_type: "inventory_analytics",
  },
]

const MonthlyWorkspace = ({
  id,
  formData,
  isSaving,
  setIsSaving,
  calendars = [],
  refresh = () => null,
}) => {
  const {
    data: { soData },
  } = useContext(SpaceContext)

  const [activeTab, setActiveTab] = useState(tabs[0].slug)

  const [allOffices, setAllOffices] = useState([])
  const [allDesks, setAllDesks] = useState([])

  useEffect(() => {
    let offices = []
    let desks = []
    for (let j = 0; j < formData.floors.length; j++) {
      const floor = formData.floors[j]
      offices = [...offices, ...floor.offices]
      desks = [...desks, ...floor.dedicatedDesks]
    }
    setAllOffices(offices)
    setAllDesks(desks)
  }, [formData.floors])

  return (
    <div>
      <Nav pills className="page-content-tabs">
        {tabs.map(({ name, slug, dashboard_type = null }) => {
          if (dashboard_type && !soData.dashboard_type) return null
          if (dashboard_type && dashboard_type !== soData.dashboard_type)
            return null
          return (
            <Button
              key={slug}
              color="default"
              className={`edit-space-tab-nav-link ${
                activeTab === slug && "active"
              }`}
              onClick={() => setActiveTab(slug)}
            >
              {name}
            </Button>
          )
        })}
      </Nav>
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabs[0].slug}>
            <Terms
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[1].slug}>
            <WorkspaceDescription
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[2].slug}>
            <ContactPerson
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[3].slug}>
            <EmailConfirmations
              id={id}
              formData={formData}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              calendars={calendars}
              refresh={refresh}
            />
          </TabPane>
          <TabPane tabId={tabs[4].slug}>
            {soData && soData.dashboard_type === "inventory_analytics" && (
              <InventoryAnalytics
                allOffices={allOffices}
                allDesks={allDesks}
                space_name={formData.space_name}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default MonthlyWorkspace
