import React, { useState } from "react"
import Section from "../../../../fields/Section"
import Input from "../../../../fields/Input"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import get from "lodash/get"
import isEmail from "validator/es/lib/isEmail"
import AdditionalIncludes from "../../../../fields/AdditionalIncludes"

const Terms = ({ id, formData, isSaving, setIsSaving }) => {
  const { makeRequest } = useAuth()

  const emails = get(formData, "on_demand_emails", "")

  const [
    on_demand_confirmation_recipients_emails_placeholders,
    set_on_demand_confirmation_recipients_emails_placeholders,
  ] = useState(emails ? emails.split(",") : [])

  const [on_demand_instructions, setOdi] = useState(
    get(formData, "on_demand_instructions", "") || ""
  )

  const saveRecipientEmails = (e) => {
    if (e) e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        on_demand_emails: on_demand_confirmation_recipients_emails_placeholders
          ? on_demand_confirmation_recipients_emails_placeholders.join(",")
          : "",
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  const save = (e) => {
    e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        on_demand_instructions,
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <Section title={"Booking confirmation recipient's email addresses"} first>
        <Row>
          <Col lg="6">
            <label>Email Address</label>
            <p>Multiple emails can be added.</p>
            <AdditionalIncludes
              placeholder={"Email"}
              values={on_demand_confirmation_recipients_emails_placeholders}
              isValidated={(value) => value && isEmail(value) && !isSaving}
              handleChange={
                set_on_demand_confirmation_recipients_emails_placeholders
              }
            />
          </Col>
          <Col lg="12" className={"d-flex flex-column  justify-content-end"}>
            <div className={"mb-3"}>
              <Button
                color={"success"}
                size={"sm"}
                onClick={saveRecipientEmails}
                disabled={
                  !on_demand_confirmation_recipients_emails_placeholders ||
                  on_demand_confirmation_recipients_emails_placeholders.length ===
                    0
                }
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Section>

      <Section title={"Instructions for on-demand bookings"}>
        <Row>
          <Col lg="12">
            <label htmlFor="">Instructions</label>
            <p>
              The content will be displayed as a list. Please use a new line for
              each list item.
              <br />
              E.g. Upon arrival please call Mary at 406-338-0889
            </p>
            {/*<TinyMce*/}
            {/*  onInit={(evt, editor) => (editorRef.current = editor)}*/}
            {/*  initialValue={on_demand_instructions}*/}
            {/*/>*/}
            <Input
              type="textarea"
              label="Instructions"
              name="instructions"
              placeholder="These instructions will be displayed at the bottom of the
              reservation email as a list. Please use a new line for a new item."
              helpText={""}
              value={on_demand_instructions}
              onChange={setOdi}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={4} md={3} lg={2} className={"mt-3"}>
            <Button color={"success"} size={"sm"} onClick={save}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>
    </React.Fragment>
  )
}

export default Terms
