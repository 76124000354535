import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import * as s from "./index.module.less"
import { format, parse } from "date-fns"
import { Link } from "gatsby"
import classNames from "classnames"

const Bookings = ({ id, formData, pastBookings, makeRequest, refresh }) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Location</th>
            <th>Date</th>
            <th>Start & end time</th>
            <th>Number of attendees</th>
            <th>Host</th>
            <th>Invitation Code</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {formData?.meetups
            ?.sort((a, b) =>
              pastBookings
                ? new Date(b.startDateTime) - new Date(a.startDateTime)
                : new Date(a.startDateTime) - new Date(b.startDateTime)
            )
            ?.map((meetup) => {
              let condition = new Date(meetup.date) > new Date()
              if (pastBookings) {
                condition = new Date(meetup.date) < new Date()
              }

              if (condition) {
                return (
                  <tr key={meetup.id}>
                    <td>{meetup.title}</td>
                    <td>
                      {new Date(meetup.startDateTime).toLocaleDateString(
                        "en-US",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td>
                      {formatTime(meetup.startTime)} -{" "}
                      {formatTime(meetup.endTime)}
                    </td>
                    <td>-</td>
                    <td>{meetup?.host || "-"}</td>
                    <td>{meetup?.invitationCode || "-"}</td>
                    <td>
                      <a
                        className={classNames({
                          [s.remove]: true,
                          "text-warning": true,
                        })}
                        href={`/admin/hubs/${meetup.id}`}
                        target={"_blank"}
                      >
                        Edit
                      </a>{" "}
                      |{" "}
                      <button
                        className={classNames({
                          [s.remove]: true,
                          "text-danger": true,
                        })}
                        onClick={async () => {
                          await makeRequest(
                            "meetup",
                            { meetupId: meetup.id },
                            "DELETE"
                          )
                          refresh(true)
                        }}
                      >
                        Remove
                      </button>
                      |{" "}
                      <button
                        className={s.remove}
                        onClick={() => {
                          if (typeof navigator !== undefined) {
                            navigator.clipboard.writeText(getUrl(meetup?.id))
                          }
                        }}
                      >
                        Copy URL
                      </button>
                    </td>
                  </tr>
                )
              }
            })}
        </tbody>
      </table>
    </>
  )
}

export default Bookings

const getUrl = (meetupId = "") => {
  return `https://${
    process.env.GATSBY_STAGE === "production" ? "" : "staging."
  }worksimply.com/meetups/${meetupId}`
}

const formatTime = (time) => {
  const date = parse(`2020-01-01 ${time}`, "yyyy-MM-dd HH:mm:ss", new Date())
  return format(date, "hh:mm a")
}
