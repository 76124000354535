import React from "react"
import Image from "../../../../Dropzones/image"
import Section from "../../../../fields/Section"
import { COMMON_IMAGES, HERO_IMAGE } from "../../../../Dropzones/constants"
import OnDemandOffices from "./OnDemandOffices"

const Images = ({ formData = {} }) => {
  if (!formData.id) return null
  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Section first>
          <Image
            spDataId={formData.id}
            category={HERO_IMAGE}
            existingImages={formData.hero_image_url}
            subtitle={
              "This is the image that’s shown as a thumbnail in the locations page and as the hero image"
            }
            label={"Main image"}
          />
        </Section>

        <Section title={"Workspace images"}>
          <Image
            spDataId={formData.id}
            category={COMMON_IMAGES}
            multiple
            existingImages={formData.common_images}
            label={""}
          />
        </Section>
      </form>

      <hr />

      <OnDemandOffices id={formData.id} formData={formData} />
    </React.Fragment>
  )
}

export default Images
