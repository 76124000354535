import React, { useContext, useEffect, useState } from "react"
import Layout from "@components/layout"
import { useAuth } from "@auth/useAuth"
import { toast } from "react-toastify"
import { SpaceContext } from "@hooks/SpaceContext"
import { Button, Nav, TabContent, TabPane } from "reactstrap"
import OnlyAuthorized from "@auth/OnlyAuthorized"
import Availability from "./TabContent/Availability"
import LocationDetails from "./TabContent/LocationDetails"
import MonthlyWorkspace from "./TabContent/MonthlyWorkspace"
import OnDemandWorkspace from "./TabContent/OnDemandWorkspace"
import Admin from "./TabContent/Admin"
import fetchHolidays from "../../services/fetchHolidays"
import userHasAccess from "../../utils/userHasAccess"

import "./styles.less"
import useLocationStore from "../../stores/useLocationStore"

const tabs = [
  {
    name: "Availability",
    slug: "availability",
  },
  {
    name: "Location Details",
    slug: "locationDetails",
  },
  {
    name: "Monthly Workspace",
    slug: "monthlyWorkspace",
    varNames: "monthly_offices_enabled",
  },
  {
    name: "On-demand Workspace",
    slug: "onDemandWorkspace",
    varNames:
      "on_demand_offices_daily_enabled,day_passes_enabled,on_demand_offices_hourly_enabled",
  },
  {
    name: "Admin",
    slug: "admin",
    permission: "manage:space-providers",
  },
]

const EditSpaceTemplate = ({ spaceId }) => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const {
    data: { user, soData },
  } = useContext(SpaceContext)

  const [activeTab, setActiveTab] = useState(tabs[0].slug)
  const [isSaving, setIsSaving] = useState(false)

  const [spaceData, setSpaceData] = useState(null)
  const [users, setUsers] = useState([])
  const [spaceProviders, setSpaceProviders] = useState([])

  const [commonHolidays, setCommonHolidays] = useState({})

  const [calendars, setCalendars] = useState([])
  const [categories, setCategories] = useState([])
  const [fetchingSpace, setFetchingSpace] = useState(false)

  const setAutoBookingsOfSelectedLocation = useLocationStore(
    (state) => state.setAutoBookingsOfSelectedLocation
  )

  const fetchSpaceFromDB = async (silent = false) => {
    if (!silent) {
      setSpaceData(null)
      setFetchingSpace(true)
    }

    setAutoBookingsOfSelectedLocation([])

    try {
      const res = await makeRequest("sp/view", { id: spaceId })
      setFetchingSpace(false)
      setSpaceData(res.space)
      setAutoBookingsOfSelectedLocation(res.autoBookings)
      if (res.spaceProviders) setUsers(res.spaceProviders)
      if (res.SPs) setSpaceProviders(res.SPs)
      if (res.calendars) setCalendars(res.calendars)
      if (res?.categories) setCategories(res.categories)
    } catch (error) {
      setFetchingSpace(false)
      console.log(error)
      toast.error("Failed to fetch space details.")
    }

    try {
      const holidays = await fetchHolidays()
      setCommonHolidays(holidays)
    } catch (e) {
      console.log(e)
    }
  }

  const getSpace = (silent = false) => {
    if (!silent) {
      setSpaceData(null)
    }
    fetchSpaceFromDB(silent)
  }
  useEffect(() => {
    if (isAuthenticated && spaceId && user && user.id && !fetchingSpace) {
      fetchSpaceFromDB()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, isAuthenticated, user])

  return (
    <Layout
      progress={!spaceData}
      customLink={{
        title: spaceData ? spaceData.space_name : null,
        link: "/locations",
      }}
    >
      {!!spaceData && (
        <>
          <select
            name=""
            id=""
            className="form-control mb-4 d-lg-none"
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
          >
            {tabs.map(({ name, slug, permission = null, varNames = null }) => {
              if (permission) {
                return (
                  <OnlyAuthorized permission={permission} key={slug}>
                    <option value={slug}>{name}</option>
                  </OnlyAuthorized>
                )
              }
              if (varNames && !userHasAccess(soData, varNames)) return false
              return (
                <option key={slug} value={slug}>
                  {name}
                </option>
              )
            })}
          </select>
          <Nav pills className="edit-space-tab-buttons d-none d-lg-flex">
            {tabs.map(({ name, slug, permission = null, varNames = null }) => {
              if (permission) {
                return (
                  <OnlyAuthorized permission={permission} key={slug}>
                    <Button
                      key={slug}
                      color="default"
                      className={`edit-space-tab-nav-link ${
                        activeTab === slug && "active"
                      }`}
                      onClick={() => setActiveTab(slug)}
                    >
                      {name}
                    </Button>
                  </OnlyAuthorized>
                )
              }
              if (varNames && !userHasAccess(soData, varNames)) return false
              return (
                <Button
                  key={slug}
                  color="default"
                  className={`edit-space-tab-nav-link ${
                    activeTab === slug && "active"
                  }`}
                  onClick={() => setActiveTab(slug)}
                >
                  {name}
                </Button>
              )
            })}
          </Nav>
          <div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={tabs[0].slug}>
                <Availability
                  spaceId={spaceId}
                  floors={spaceData ? spaceData.floors : []}
                  refresh={getSpace}
                  formData={spaceData || {}}
                  setIsSaving={setIsSaving}
                />
              </TabPane>
              <TabPane tabId={tabs[1].slug}>
                <LocationDetails
                  formData={spaceData || {}}
                  id={spaceId}
                  isSaving={isSaving}
                  setIsSaving={setIsSaving}
                  calendars={calendars}
                  refresh={getSpace}
                />
              </TabPane>
              {userHasAccess(soData, tabs[2].varNames) && (
                <TabPane tabId={tabs[2].slug}>
                  <MonthlyWorkspace
                    formData={spaceData || {}}
                    id={spaceId}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    calendars={calendars}
                    refresh={getSpace}
                  />
                </TabPane>
              )}
              {userHasAccess(soData, tabs[3].varNames) && (
                <TabPane tabId={tabs[3].slug}>
                  <OnDemandWorkspace
                    formData={spaceData || {}}
                    id={spaceId}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    refresh={getSpace}
                    commonHolidays={commonHolidays}
                  />
                </TabPane>
              )}
              <OnlyAuthorized permission={"manage:space-providers"}>
                <TabPane tabId={tabs[4].slug}>
                  <Admin
                    formData={spaceData || {}}
                    id={spaceId}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    refresh={getSpace}
                    spaceProviders={spaceProviders || []}
                    users={users || []}
                    categories={categories}
                  />
                </TabPane>
              </OnlyAuthorized>
            </TabContent>
          </div>
        </>
      )}
    </Layout>
  )
}

export default EditSpaceTemplate
