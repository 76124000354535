import { create } from "zustand"

const useLocationStore = create((set) => ({
  locations: {},
  autoBookingsOfSelectedLocation: [],
  setAutoBookingsOfSelectedLocation: (autoBookingsOfSelectedLocation) =>
    set({ autoBookingsOfSelectedLocation }),
}))

export default useLocationStore
