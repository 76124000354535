import React, { useState } from "react"
import get from "lodash/get"
import Input from "../../fields/Input"
import Section from "../../fields/Section"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const Category = ({ formData, refresh = () => null, categories }) => {
  const { makeRequest } = useAuth()

  const [categoryId, setCategoryId] = useState("")

  const add = async () => {
    if (!formData.id || !categoryId) return
    const data = {
      id: formData.id,
      categoryId,
    }
    try {
      await makeRequest("categories/add-to-location", data)
      toast.success("Added category.")
    } catch (e) {
      console.log(e)
      toast.error("Failed to add the category.")
    }

    refresh()
  }

  const deleteRel = async (id) => {
    await makeRequest("categories/delete-relation", {
      space_id: formData.id,
      category_id: id,
    })
    refresh()
  }

  return (
    <>
      <Section title={"Category"}>
        <Row>
          <Col md="6">
            <label htmlFor="category">Category</label>
            <Input
              type="select"
              name="category"
              value={categoryId}
              onChange={setCategoryId}
            >
              <option value={""} disabled>
                Please select
              </option>
              {categories.map((cat) => (
                <option value={cat.id} key={cat.id}>
                  {cat.name}
                </option>
              ))}
            </Input>
          </Col>
          {formData?.categories?.length > 0 && (
            <Col md="6">
              <label htmlFor="timezone">Existing Categories</label>
              <ul>
                {formData?.categories.map((cat) => (
                  <li key={cat.id}>
                    <span>{cat.name}</span> |
                    <button
                      className="btn btn-link btn-sm p-0 text-danger"
                      style={{ minWidth: "0px" }}
                      onClick={() => deleteRel(cat.id)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
          )}
          <Col md="12">
            <Button color={"success"} onClick={add} disabled={!categoryId}>
              Add
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default Category
