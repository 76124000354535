import React, { useState } from "react"
import Section from "../../fields/Section"
import Image from "../../Dropzones/image"
import { PLAN_BACKGROUND, PLAN_SVG } from "../../Dropzones/constants"
import { Button, Col, Row } from "reactstrap"
import Floor from "../../fields/Floor"
import { useAuth } from "@auth/useAuth"
import { MANAGE_SP } from "@auth/scopes"

import "../styles.less"
import RadioButton from "../../fields/_Radio_Button"
import { toast } from "react-toastify"
import RadioBox from "../../fields/RadioSwitch"

const SpaceLayout = ({ spaceId, floors = [], refresh, formData = {} }) => {
  const [visible, setVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const { hasScope, makeRequest } = useAuth()
  const createFloor = async () => {
    setIsLoading(true)
    await makeRequest("floors", {
      spaceId: spaceId,
      floorName: "Floor " + (floors.length + 1),
    })
    setIsLoading(false)
    refresh()
  }

  const changeFloorName = async (e, id) => {
    await makeRequest("floors/change-name", {
      id,
      name: e.target.value,
    })
    toast.success("Floor name updated.")
  }

  const deleteFloor = async (id) => {
    await makeRequest("floors/delete", {
      id,
    })
    refresh()
  }

  const bundleSpace = floors && floors.length > 0 && floors[0].bundle_space

  const [isLive, setIsLive] = useState(formData.is_live)
  const [progress, setProgress] = useState(false)

  const handleLiveToggle = async () => {
    setIsLive(!isLive)
    setProgress(true)
    const data = {
      id: formData.id,
      value: !isLive,
    }
    await makeRequest("sp/toggle-space", data)
      .then((res) => {
        toast.success("Status changed.")
      })
      .catch((error) => {
        setIsLive(!isLive)
        toast.error("Failed to update the status. Please try again.")
      })
    setTimeout(() => {
      setProgress(false)
    }, 500)
  }

  return (
    <React.Fragment>
      <Section last first>
        <Row>
          <Col lg="6">
            <RadioBox
              label="Active space?"
              onChange={handleLiveToggle}
              name="coffee"
              value={isLive}
              disabled={progress}
            />
          </Col>
        </Row>
      </Section>

      {floors.map((floor) => {
        let total_seats = 0
        if (floor.bundle_space) {
          for (
            let index = 0;
            index < floors[0].dedicatedDesks.length;
            index++
          ) {
            const element = floors[0].dedicatedDesks[index]
            total_seats += element.desks_available
          }
          for (let index = 0; index < floors[0].offices.length; index++) {
            const element = floors[0].offices[index]
            total_seats += element.max_capacity
          }
        }
        return (
          <div className={"mt-5"} key={floor.id}>
            <h3 className={"d-flex"}>
              <input
                type="text"
                className={"admin-floor-name"}
                defaultValue={floor.floor_name}
                onBlur={(e) => changeFloorName(e, floor.id)}
              />
              {hasScope(MANAGE_SP) && (
                <button
                  className={"btn btn-danger ml-auto btn-sm"}
                  onClick={() => deleteFloor(floor.id)}
                >
                  Delete floor
                </button>
              )}
            </h3>
            {hasScope(MANAGE_SP) && floors.length === 1 && (
              <ToggleSwitch
                initialValue={floor.bundle_space}
                floorId={floor.id}
                refresh={refresh}
              />
            )}

            <div
              className={`${visible ? "d-block" : "d-none"}`}
              style={{ overflow: "hidden" }}
            >
              {(floor.offices.length > 0 ||
                floor.dedicatedDesks.length > 0) && (
                <ul className="space-layout-legend">
                  {total_seats > 0 && (
                    <li className="total-seats">
                      Total seat count - {total_seats}
                    </li>
                  )}
                  <li className="available not">Not available</li>
                  <li className="available">Available today</li>
                  <li className="available future">Available soon</li>
                </ul>
              )}
              {floor.map_background_url && floor.map_svg_url && (
                <Floor
                  floorId={floor.id}
                  svg={floor.map_svg_url}
                  background={floor.map_background_url}
                  offices={floor.offices}
                  dedicatedDesks={floor.dedicatedDesks}
                  refresh={refresh}
                  setVisibility={setVisible}
                  formData={formData}
                  bundleSpace={floor.bundle_space}
                />
              )}
            </div>

            {hasScope(MANAGE_SP) && (
              <Section>
                <Image
                  existingImages={floor.map_background_url}
                  spDataId={floor.id}
                  category={PLAN_BACKGROUND}
                  reload={refresh}
                  label={"Space Layout (JPG)"}
                  accept={"image/jpeg"}
                />
              </Section>
            )}
            {hasScope(MANAGE_SP) && (
              <Section>
                <Image
                  spDataId={floor.id}
                  label={"Space Layout (SVG)"}
                  existingImages={floor.map_svg_url}
                  category={PLAN_SVG}
                  displayText="Upload space layout SVG"
                  reload={refresh}
                  // accept={"application/svg+xml"}
                />
              </Section>
            )}
          </div>
        )
      })}

      {hasScope(MANAGE_SP) && !bundleSpace && (
        <Button
          color="primary"
          size="lg"
          disabled={isLoading}
          className={"btn-square ml-3"}
          onClick={() => {
            if (!isLoading) {
              createFloor()
            }
          }}
        >
          Add Floor
        </Button>
      )}
    </React.Fragment>
  )
}

export default SpaceLayout

const ToggleSwitch = ({ initialValue, floorId, refresh = () => null }) => {
  const { makeRequest } = useAuth()
  const [value, setValue] = useState(initialValue || false)
  const [progress, setProgress] = useState("")

  const handleChange = async () => {
    setValue(!value)
    try {
      setProgress("active")
      await makeRequest("sp/bundle-space/toggle", {
        floor_id: floorId,
        value: !value,
      }).then(function (data) {
        setProgress("")
        if (data.message !== "Success") {
          setValue(value)
        } else {
          refresh()
        }
      })
    } catch (error) {
      setProgress("")
      setValue(value)
    }
  }

  if (!value) {
    return null
  }

  return (
    <div className="d-flex align-items-center">
      <div className={"mr-3"}>Is this a bundled space?</div>
      <RadioButton
        name={"bundle"}
        value={initialValue}
        onChange={handleChange}
      />
    </div>
  )
}
