import React from "react"
import { FormGroup, Label } from "reactstrap"
import RadioButton from "./_Radio_Button"

const ToggleSwitch = ({
  value,
  onChange,
  className = "",
  label,
  name,
  inline = false,
}) => {
  return (
    <FormGroup
      className={`${className} ${
        inline ? "d-flex align-items-center my-3" : ""
      }`}
    >
      {label && (
        <Label for={name} className={inline ? "my-1 mr-1" : "d-block"}>
          {label}
        </Label>
      )}
      <RadioButton name={name} value={value} onChange={onChange} />
    </FormGroup>
  )
}

export default ToggleSwitch
