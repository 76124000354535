import React, { useEffect, useState } from "react"
import Section from "../../../../fields/Section"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import get from "lodash/get"
import SingleImage from "../../../../Dropzones/SingleImage"
import Input from "../../../../fields/Input"

const WorkspaceDescription = ({ formData }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    if (!formData.id) return
    setItems([
      {
        name: "Private Offices",
        slug: "private_offices",
        description: get(formData, "private_offices_description", ""),
        image: get(formData, "private_offices_icon", ""),
      },
      {
        name: "Dedicated Desks",
        slug: "dedicated_desks",
        description: get(formData, "dedicated_desks_description", ""),
        image: get(formData, "dedicated_desks_icon", ""),
      },
      {
        name: "On-Demand Offices",
        slug: "on_demand_offices",
        description: get(formData, "on_demand_offices_description", ""),
        image: get(formData, "on_demand_offices_icon", ""),
      },
      {
        name: "Day Passes",
        slug: "day_passes",
        description: get(formData, "day_passes_description", ""),
        image: get(formData, "day_passes_icon", ""),
      },
      {
        name: "Virtual Offices",
        slug: "virtual_offices",
        description: get(formData, "virtual_offices_description", ""),
        image: get(formData, "virtual_offices_icon", ""),
      },
      {
        name: "Hot Desk",
        slug: "hot_desk",
        description: get(formData, "hot_desk_description", ""),
        image: get(formData, "hot_desk_icon", ""),
      },
    ])
  }, [formData])

  const [price, setPrice] = useState(
    get(formData, "price_per_day_pass", 0) || 0
  )

  return (
    <React.Fragment>
      {items.map(({ name, slug, category, image, description = "" }, idx) => {
        return (
          <Section title={name} first={idx === 0} key={idx}>
            <Row>
              <Col md="3">
                <label className={"label"}>Icon</label>
                <br />
                <SingleImage
                  spaceId={formData.id}
                  name={`${slug}_icon`}
                  existingImage={image}
                  label=""
                  circle
                  customWidth
                />
              </Col>
              <Col md="6">
                <InputSection
                  description={description}
                  slug={slug}
                  id={formData.id}
                />
              </Col>
            </Row>
          </Section>
        )
      })}
    </React.Fragment>
  )
}

export default WorkspaceDescription

const InputSection = ({ description = "", slug, id }) => {
  const { makeRequest } = useAuth()
  const [desc, setDesc] = useState(description || "")

  const update = async () => {
    try {
      await makeRequest("sp/update", {
        id,
        data: {
          [`${slug}_description`]: desc,
        },
      })
      toast.success("Description saved")
    } catch (e) {
      toast.error("Failed to save the description")
    }
  }
  return (
    <>
      <Input
        type="text"
        label="Description"
        placeholder="Description"
        helpText={
          "Please keep this field empty to hide this section from the location profile."
        }
        value={desc}
        onChange={(value) => setDesc(value)}
      />
      <Button color={"success"} size={"sm"} onClick={update}>
        Save
      </Button>
    </>
  )
}
