import React, { useEffect, useState } from "react"
import get from "lodash/get"
import Input from "../../fields/Input"
import Section from "../../fields/Section"
import { useAuth } from "@auth/useAuth"
import {
  Button,
  Col,
  Input as ReactStrapInput,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import Tax from "./Tax"
import Location from "./Location"
import OfficeRndId from "./OfficeRndId"
import Category from "./Category"
import TemplateType from "./TemplateType"
import RetryPayment from "./RetryPayment"
import RadioBox from "../../fields/RadioSwitch"
import AutoBookings from "./AutoBookings"

const Admin = ({
  id,
  formData,
  isSaving,
  setIsSaving,
  refresh,
  spaceProviders = [],
  users = [],
  categories = [],
}) => {
  const { makeRequest } = useAuth()

  const [slug, setSlug] = useState(get(formData, "slug", ""))
  const [owner, setOwner] = useState(get(formData, "user_id"))
  const [belongsTo, setBelongsTo] = useState(
    get(formData, "space_operator_id") || ""
  )
  const [validOwner, setVo] = useState(true)
  useEffect(() => {
    if (!formData || !formData.user_id) return
    setVo(
      formData && formData.user && formData.user.id && formData.user.auth0_id
    )
  }, [formData, users])

  const [stripeId, setStripeId] = useState(
    formData && formData.stripe_connected_account_id
      ? formData.stripe_connected_account_id
      : ""
  )

  const [hubSpotDealOwnerID, setHubSpotDealOwnerID] = useState(
    formData && formData.hubspot_deal_owner_id
      ? formData.hubspot_deal_owner_id
      : ""
  )

  const [serviceFeePercentageWorksimply, setServiceFeePercentageWorksimply] =
    useState(
      get(formData, "on_demand_service_fee_percentage_source_worksimply")
    )
  const [serviceFeePercentageClient, setServiceFeePercentageClient] = useState(
    get(formData, "on_demand_service_fee_percentage_source_client")
  )

  const deleteSpace = async () => {
    if (typeof window !== "undefined" && window.confirm("Delete this space?")) {
      await makeRequest("sp/delete", {
        id,
      })
        .then(() => null)
        .catch(() => null)
      window.location.href = `${window.location.origin}/admin/space-operators`
    } else {
      console.log("canceled")
    }
  }

  const updateOnDemandData = async () => {
    if (!formData.id || !stripeId) return
    const data = {
      id: formData.id,
      stripeId,
      serviceFeePercentageWorksimply,
      serviceFeePercentageClient,
    }
    await makeRequest("sp/on-demand", data)
      .then(() => null)
      .catch(() => null)
    toast.success("Updated on-demand data.")
  }

  const updateHubSpotOwnerID = async () => {
    if (!formData.id || !hubSpotDealOwnerID) return
    const data = {
      id: formData.id,
      hubSpotDealOwnerID,
    }
    await makeRequest("sp/hubspot-deal-owner-id", data)
      .then(() => null)
      .catch(() => null)
    toast.success("Updated on-demand data.")
  }

  const updateSlug = async () => {
    if (!formData.id || !slug) return
    const data = {
      id: formData.id,
      custom: true,
      data: {
        slug,
      },
    }
    await makeRequest("sp/edit", data)
      .then(() => null)
      .catch(() => null)
    toast.success("Updated slug.")
  }

  const updateOwnership = async () => {
    if (!formData.id || !owner) return
    const data = {
      space_id: formData.id,
      owner,
      belongsTo,
    }
    await makeRequest("sp/update-user", data)
      .then(() => null)
      .catch(() => null)
    toast.success("Updated user.")
    refresh()
  }

  return (
    <>
      <Section title={"On-demand data"} first>
        <Row>
          <Col md="6">
            <Input
              type="text"
              label="Connected Account ID"
              placeholder="ex: acct_1Ha0t4Jo8ARofgK0"
              value={stripeId}
              onChange={(value) => setStripeId(value)}
            />
          </Col>
          <Col md="3" className={"mb-3 mb-md-0"}>
            <label htmlFor="" className="form-label">
              Service fee percentage
            </label>
            <InputGroup>
              <ReactStrapInput
                min={0}
                max={50}
                type="number"
                step="1"
                label="Service fee percentage"
                placeholder="ex: 10"
                value={serviceFeePercentageWorksimply}
                onChange={(e) => {
                  if (Number(e.target.value) < 51)
                    setServiceFeePercentageWorksimply(e.target.value)
                }}
              />
              <InputGroupAddon addonType="append" className={"ml-0"}>
                %
              </InputGroupAddon>
            </InputGroup>
            <span>Source - Worksimply</span>
          </Col>
          <Col md="3" className={"mb-3 mb-md-0"}>
            <label htmlFor="" className="form-label">
              Service fee percentage
            </label>
            <InputGroup>
              <ReactStrapInput
                min={0}
                max={50}
                type="number"
                step="1"
                placeholder="ex: 10"
                className={"mb-0"}
                value={serviceFeePercentageClient}
                onChange={(e) => {
                  if (e.target.value < 51)
                    setServiceFeePercentageClient(e.target.value)
                }}
              />
              <InputGroupAddon addonType="append" className={"ml-0"}>
                %
              </InputGroupAddon>
            </InputGroup>
            <span>Source - Client's website</span>
          </Col>
          <Col md="12">
            <Button color={"success"} onClick={updateOnDemandData}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <AutoBookings formData={formData} refresh={refresh} />

      <RetryPayment
        formData={formData}
        refresh={refresh}
        categories={categories}
      />

      <Tax formData={formData} refresh={refresh} />

      <Category formData={formData} refresh={refresh} categories={categories} />

      <TemplateType formData={formData} refresh={refresh} />

      <Location formData={formData} refresh={refresh} />

      <Section title={"HubSpot data"}>
        <Row>
          <Col md="6">
            <Input
              type="text"
              label="HubSpot Owner ID"
              value={hubSpotDealOwnerID}
              onChange={(value) => setHubSpotDealOwnerID(value)}
            />
          </Col>
          <Col md="12">
            <Button
              color={"success"}
              onClick={updateHubSpotOwnerID}
              disabled={!hubSpotDealOwnerID}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <Section title={`Slug`}>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              helpText={`Please use only lower case letters and dashes. i.e.: worksimply-peter-street
<br />
Slug should be unique across space operator.`}
              value={slug}
              onChange={(value) => setSlug(value)}
            />
          </Col>
          <Col md="12">
            <Button color={"success"} onClick={updateSlug}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <OfficeRndId formData={formData} refresh={refresh} />

      <Section title={`Location Operator`}>
        <Row>
          <Col lg="6">
            <Input
              type="select"
              name="spaceType"
              placeholder="# of months"
              value={belongsTo}
              onChange={setBelongsTo}
              disabled={get(formData, "space_operator_id")}
            >
              <option value="" disabled>
                Not assigned
              </option>
              {spaceProviders &&
                spaceProviders.length > 0 &&
                spaceProviders.map((sp) => (
                  <option value={sp.id} key={sp.id}>
                    {sp.name}
                  </option>
                ))}
            </Input>
          </Col>
          <Col md="12">
            <Button
              color={"success"}
              onClick={updateOwnership}
              disabled={!!get(formData, "space_operator_id")}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <Section title={""}>
        <Row>
          <Col lg="6">
            <Button color={"danger"} onClick={deleteSpace}>
              Delete Location
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default Admin
