import React, { useState } from "react"
import Section from "../../../../fields/Section"
import Input from "../../../../fields/Input"
import { useAuth } from "@auth/useAuth"
import { MANAGE_SP } from "@auth/scopes"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import Image from "../../../../Dropzones/image"
import { USER_IMAGE } from "../../../../Dropzones/constants"
import { toast } from "react-toastify"
import get from "lodash/get"
import isUUID from "validator/es/lib/isUUID"

const MonthlyWorkspace = ({
  id,
  formData,
  isSaving,
  setIsSaving,
  refresh,
  calendars = [],
}) => {
  const { makeRequest, hasScope } = useAuth()

  const [contactName, setContactName] = useState(formData.contact_name || "")
  const [onDemandEmails, setOnDemandEmails] = useState(
    formData.on_demand_emails || ""
  )

  const [calendar_id, setCalendarId] = useState(formData.calendar_id || "")

  const [email, setEmail] = useState(formData.contact_email || "")
  const [company, setCompany] = useState(
    get(formData, "user.company_name") || ""
  )
  const [position, setPosition] = useState(formData.contact_position || "")

  const [accountEmail, setAccountEmail] = useState(get(formData, "user.email"))

  const onSendCreationEmail = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    try {
      await makeRequest("user/finish", {
        id: formData.user_id,
        type: "space-provider",
      })
      setIsSaving(false)
      toast.success("Email sent.")
    } catch (e) {
      setIsSaving(false)
      toast.error("Failed to send the email.")
    }
  }

  const data = {
    contactName,
    email,
    position,
    company,
    onDemandEmails,
    calendar_id: calendar_id && isUUID(calendar_id) ? calendar_id : null,
    user_id: get(formData, "user.id"),
    contact: true,
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    try {
      await makeRequest("sp/edit", { id, data })
      if (accountEmail !== get(formData, "user.email")) {
        await makeRequest("sp/accountEmail", "PUT", {
          id: formData.user_id,
          newEmail: accountEmail,
        })
      }
      setIsSaving(false)
      toast.success("Details saved")
      // refresh()
    } catch (e) {
      setIsSaving(false)
      toast.error("Failed to save the details")
    }
  }

  const spEmailChanged = get(formData, "user.email") !== accountEmail

  return (
    <React.Fragment>
      <Section first>
        <Row>
          <Col lg="12" className={"mb-4"}>
            <Image
              spDataId={formData.id}
              category={USER_IMAGE}
              existingImages={formData.user_image_url}
              label="Profile Picture"
              circle
              customWidth
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              label="Full Name"
              name="contactName"
              placeholder="Full Name"
              value={contactName}
              onChange={setContactName}
            />
          </Col>
          <Col lg="6">
            <Input
              type="text"
              label="Position"
              name="position"
              placeholder="E.g. Community Manager"
              value={position}
              onChange={setPosition}
            />
          </Col>
          {/*<Col lg="6">*/}
          {/*  <Input*/}
          {/*    type="text"*/}
          {/*    label="Email address"*/}
          {/*    name="email"*/}
          {/*    placeholder="E-mail"*/}
          {/*    value={email}*/}
          {/*    onChange={setEmail}*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col sm={6}>
            <Input
              type="select"
              label={"Assigned Calendar"}
              name="officeTermCommitment"
              placeholder="# of months"
              value={calendar_id}
              onChange={setCalendarId}
            >
              <option value="">
                {calendars.length === 0
                  ? "No calendars available"
                  : "No calendar assigned"}
              </option>
              {calendars.length > 0 &&
                calendars.map((calendar) => (
                  <option value={calendar.id} key={calendar.id}>
                    {calendar.name || "Default"}
                  </option>
                ))}
            </Input>
          </Col>
        </Row>

        {/*<Section>*/}
        {/*  <Row>*/}
        {/*    <Col lg="6">*/}
        {/*      <Input*/}
        {/*        type="text"*/}
        {/*        label="Email address for on-demand spaces"*/}
        {/*        name="onDemandEmail"*/}
        {/*        placeholder="E-mail"*/}
        {/*        value={onDemandEmails}*/}
        {/*        onChange={setOnDemandEmails}*/}
        {/*        helpText={*/}
        {/*          "Multiple emails can be added separated with a comma. (hello@yourdomain.com,info@yourdomain.com)"*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</Section>*/}

        <Row>
          <Col lg="12">
            <Button
              color="success"
              size="lg"
              className="btn-square"
              disabled={isSaving}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      {false && hasScope(MANAGE_SP) && (
        <Card className="card-border border-secondary mb-3">
          <CardHeader className="text-secondary">
            Account Information
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="6">
                <Input
                  type="text"
                  label="Email address"
                  name="email"
                  placeholder="E-mail"
                  error={
                    spEmailChanged
                      ? "Please save changes before sending the email."
                      : false
                  }
                  value={accountEmail}
                  onChange={setAccountEmail}
                />

                <Button
                  color="success"
                  size="lg"
                  className="btn-square"
                  disabled={isSaving || spEmailChanged}
                  onClick={onSendCreationEmail}
                >
                  Send Email
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

export default MonthlyWorkspace
