import React, { useEffect, useState } from "react";

import { Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
// import InputMask from "react-input-mask";

const TextInput = ({
  label,
  type,
  placeholder = "",
  name,
  className = "",
  onChange = () => null,
  onBlur = () => null,
  value,
  error = false,
  col = 12,
}) => {
  const [displayValue, setDisplayValue] = useState(value || 0)

  useEffect(() => {
    setDisplayValue(value.toLocaleString())
  }, [value])

  const handleChange = e => {
    let val = e.target.value ? parseInt(e.target.value.split(",").join("")) : 0

    if (val <= 100000) {
      setDisplayValue(val.toLocaleString())
      onChange(val)
    }
  }
  return (
    <FormGroup className={className}>
      <Row>
        <Col md={col}>
          {label && <Label for={name}>{label}</Label>}
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <div className="input-group-text">$</div>
            </InputGroupAddon>
            <Input
              type={type}
              name={name}
              id={name}
              placeholder={placeholder}
              value={displayValue}
              onBlur={onBlur}
              onChange={handleChange}
            />
          </InputGroup>
          {error && <p className="text-danger mt-1">{error}</p>}
        </Col>
      </Row>
    </FormGroup>
  )
}

export default TextInput
