import {
  availableColor,
  brandColor,
  futureAvailableColor,
  occupiedColor,
} from "./colors"
import { onEnter, onLeave } from "./utils"

const prepareSvgBlock = (element, selectedOfficeOrDesk) => {
  const existingStrokeWidth = element.getAttribute("stroke-width")

  if (existingStrokeWidth && parseInt(existingStrokeWidth) < 10) {
    element.setAttribute("class", "add-hover-cursor")
  }

  // initialize
  element.setAttribute("fill-opacity", "0")
  element.setAttribute("fill", brandColor)

  // Register event listeners
  element.addEventListener("mouseenter", onEnter)
  element.addEventListener("mouseleave", onLeave)
  element.setAttribute("stroke", "transparent")
  if (selectedOfficeOrDesk) {
    element.setAttribute("stroke-width", "6")
    element.setAttribute("class", "has-block")
    element.setAttribute("fill", availableColor)
    element.setAttribute("fill-opacity", "0.5")
    const today = new Date()
    const availableDate = new Date(selectedOfficeOrDesk.space_available_from)

    if (
      !selectedOfficeOrDesk.space_available &&
      !selectedOfficeOrDesk.space_available_from
    ) {
      element.setAttribute("fill", occupiedColor)
      element.setAttribute("fill-opacity", "0.5")
    } else if (
      selectedOfficeOrDesk.space_available ||
      availableDate.getTime() <= today.getTime()
    ) {
      // Available now
      element.setAttribute("fill-opacity", " 0.5")
      element.setAttribute("stroke-width", " 2")
      element.setAttribute("stroke", "white")
    } else {
      // available in the future
      element.setAttribute("fill", futureAvailableColor)
      element.setAttribute("fill-opacity", "0.5")
      element.setAttribute("stroke", "white")
    }
  }
}

export default prepareSvgBlock
