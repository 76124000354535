export const innerElements = floorId => {
  return `#space-layout-container-${floorId} svg path, #space-layout-container-${floorId} svg rect`
}

export const OFFICE = "office"
export const DEDICATED_DESK = "dd"

export const getIdentifierFromSvg = element => {
  let whitelistedAttributes = []
  let identifier = []
  switch (element.tagName) {
    case "rect":
      whitelistedAttributes = ["width", "x", "y", "height"]
      break
    case "path":
      whitelistedAttributes = ["d"]
      break
    default:
      whitelistedAttributes = []
      break
  }

  for (let i = 0; i < element.attributes.length; i++) {
    for (let j = 0; j < whitelistedAttributes.length; j++) {
      if (element.attributes[i].nodeName === whitelistedAttributes[j]) {
        identifier.push(
          whitelistedAttributes[j] + ":" + element.attributes[i].nodeValue
        )
      }
    }
  }

  return identifier.join("|")
}

export const onEnter = event => {
  const strokeWidth = parseInt(event.target.getAttribute("stroke-width"), 10)
  if (strokeWidth < 10) {
    event.target.setAttribute("fill-opacity", "0.8")
  }
}

export const onLeave = event => {
  const strokeWidth = parseInt(event.target.getAttribute("stroke-width"), 10)
  const existingClassName = event.target.getAttribute("class")
  const isActive =
    existingClassName && existingClassName.indexOf("has-block") > -1
  if (strokeWidth < 10) {
    const opacityValue = isActive ? "0.5" : "0"
    // event.target.setAttribute("fill-opacity", 0)
    event.target.setAttribute("fill-opacity", opacityValue)
  }
}
