import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Progress } from "reactstrap"
import { v4 as uuid } from "uuid"
import { useAuth } from "../../../../../../../auth/useAuth"
import createImage from "../../../../OnDemandWorkspace/Sections/MeetingRooms/createImage"

/**
 * @john check this file for updating the image
 * 1. get the signed url from AWS
 * 2. PUT request with the to upload the file
 * 3. Save the URL in the DB
 */

const OnDemandOffices = ({ id, refresh = () => null, formData }) => {
  const { makeRequest } = useAuth()
  const [image, setImage] = useState("")

  useEffect(() => {
    setImage(formData?.default_office_image_url || "")
  }, [formData])

  const [imageProgress, setImageProgress] = useState(false)

  const onDrop = (files) => {
    setImageProgress(true)
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const file = files[i]
        reader.readAsDataURL(file)
        reader.onload = async (e) => {
          const response = await makeRequest(
            "upload/single-image/get-signed-url",
            {
              key: `locations/${uuid()}.jpg`,
              type: "image/jpeg",
            }
          )

          const mainImage = await createImage(file)

          const blobDataForMain = await fetch(mainImage).then((res) =>
            res.blob()
          )

          await fetch(response.uploadURL, {
            method: "PUT",
            body: blobDataForMain,
            mode: "cors",
          })

          setImageProgress(false)

          setImage(response.Url)

          await makeRequest("sp/update", {
            id,
            data: {
              default_office_image_url: response.Url,
            },
          })
        }
      }
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-6 d-flex align-items-center">
          <div>
            <strong>Default image for day offices search results</strong>
            <br />
            <span>This is the image shown as the thumbnail</span>
          </div>
        </div>
        <div className="col-sm-6 d-flex align-items-center justify-content-end">
          <div
            style={{
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${image || ""})`,
              width: "200px",
            }}
          >
            <Dropzone
              disabled={imageProgress}
              multiple={false}
              accept={""}
              // to pass the category with the image
              onDrop={(files) => onDrop(files)}
              // onFileDialogCancel={this.onCancel.bind(this)}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={`dropzone-custom-wrapper `}>
                  <input {...getInputProps()} />
                  <div className={`dropzone-content`}>
                    {imageProgress ? (
                      <Progress
                        animated
                        bar
                        value="100"
                        style={{ height: "8px" }}
                      />
                    ) : (
                      <p className="mb-0">Drag / Click to select</p>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnDemandOffices
