import React, { useRef, useState } from "react"
import Section from "../../../../fields/Section"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import isEmail from "validator/es/lib/isEmail"
import get from "lodash/get"
import AdditionalIncludes from "../../../../fields/AdditionalIncludes"
import TinyMce from "../../../../../../components/TinyMce"

const Terms = ({ id, formData, isSaving, setIsSaving, calendars = [] }) => {
  const { makeRequest, hasScope } = useAuth()

  const introRef = useRef(null)
  const footerRef = useRef(null)

  const [monthly_tour_email_intro] = useState(
    get(formData, "monthly_tour_email_intro", "") || ""
  )
  const [monthly_tour_email_footer] = useState(
    get(formData, "monthly_tour_email_footer", "") || ""
  )

  const emails = get(
    formData,
    "monthly_tour_confirmation_recipients_emails",
    ""
  )

  const get_in_touch_emails = get(formData, "get_in_touch_emails", "")

  const [
    monthly_tour_confirmation_recipients_emails_placeholders,
    set_monthly_tour_confirmation_recipients_emails_placeholders,
  ] = useState(emails ? emails.split(",") : [])

  const [
    get_in_touch_emails_placeholders,
    set_get_in_touch_emails_placeholders,
  ] = useState(get_in_touch_emails ? get_in_touch_emails.split(",") : [])

  const saveRecipientEmails = (e) => {
    e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        monthly_tour_confirmation_recipients_emails:
          monthly_tour_confirmation_recipients_emails_placeholders
            ? monthly_tour_confirmation_recipients_emails_placeholders.join(",")
            : "",
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  const saveGetInTouchEmails = (e) => {
    e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        get_in_touch_emails: get_in_touch_emails_placeholders
          ? get_in_touch_emails_placeholders.join(",")
          : "",
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  const save = (e) => {
    e.preventDefault()
    setIsSaving(true)
    makeRequest("sp/edit", {
      id,
      custom: true,
      data: {
        monthly_tour_email_footer: footerRef.current
          ? footerRef.current.getContent()
          : "",
        monthly_tour_email_intro: introRef.current
          ? introRef.current.getContent()
          : "",
      },
    })
      .then(function (res) {
        setIsSaving(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setIsSaving(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <Section title={"Tour confirmation recipient's email addresses"} first>
        <Row>
          <Col lg="6">
            <label>Email Address</label>
            <p>Multiple emails can be added.</p>
            <AdditionalIncludes
              placeholder={"Email"}
              values={monthly_tour_confirmation_recipients_emails_placeholders}
              isValidated={(value) => value && isEmail(value)}
              handleChange={
                set_monthly_tour_confirmation_recipients_emails_placeholders
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm={4} md={3} lg={2} className={"mt-3"}>
            <Button
              color={"success"}
              size={"sm"}
              onClick={saveRecipientEmails}
              disabled={
                !monthly_tour_confirmation_recipients_emails_placeholders ||
                monthly_tour_confirmation_recipients_emails_placeholders.length ===
                  0
              }
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <Section title={"Inquiry form recipient’s email addresses"}>
        <Row>
          <Col lg="6">
            <label>Email Address</label>
            <p>Multiple emails can be added.</p>
            <AdditionalIncludes
              placeholder={"Email"}
              values={get_in_touch_emails_placeholders}
              isValidated={(value) => value && isEmail(value)}
              handleChange={set_get_in_touch_emails_placeholders}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={4} md={3} lg={2} className={"mt-3"}>
            <Button
              color={"success"}
              size={"sm"}
              onClick={saveGetInTouchEmails}
              disabled={
                !get_in_touch_emails_placeholders ||
                get_in_touch_emails_placeholders.length === 0
              }
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <Section title={"Details for tour emails"}>
        <Row>
          <Col lg="12">
            <label htmlFor="">Tour confirmation intro description</label>
            <p>
              This is the description shown at the beginning of the tour
              confirmation email that customers receive. E.g. Thank you for your
              interest in our King West location! We are looking forward to
              seeing you.
            </p>
            <TinyMce
              onInit={(evt, editor) => (introRef.current = editor)}
              initialValue={monthly_tour_email_intro}
            />
          </Col>

          <Col lg="12">
            <label htmlFor="" className={"mt-5"}>
              Tour confirmation footer details
            </label>
            <p>
              This is the information shown at the end of the tour confirmation
              email that customers receive. E.g. Need to re-schedule? Please let
              us know by sending us an email to hello@yourdomain.com.
            </p>
            <TinyMce
              onInit={(evt, editor) => (footerRef.current = editor)}
              initialValue={monthly_tour_email_footer}
            />
          </Col>
          <Col sm={4} md={3} lg={2} className={"mt-3"}>
            <Button color={"success"} size={"sm"} onClick={save}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>
    </React.Fragment>
  )
}

export default Terms
