import React, { useEffect, useState } from "react"
import Section from "../../../../fields/Section"
import Input from "../../../../fields/Input"
import { useAuth } from "@auth/useAuth"
import Datepicker from "react-datepicker"
import { toast } from "react-toastify"
import { format, parse } from "date-fns"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

const OutOfOfficeDates = ({ space_id, commonHolidays = [], space = {} }) => {
  const { makeRequest } = useAuth()

  const [date, setDate] = useState(null)
  const [note, setNote] = useState("")

  const [allHolidays, setAllHolidays] = useState([])

  const [customHolidays, setCustomHolidays] = useState(
    space.customHolidays || []
  )

  useEffect(() => {
    if (customHolidays && customHolidays.length >= 0) {
      handleAllHolidays(customHolidays, [])
    }
  }, [])

  const handleAllHolidays = (custom, common) => {
    const customHolidayList = custom.map(({ date }) => {
      const arr = date.split("T")
      if (!arr || arr.length === 0) {
        return null
      }
      return parse(arr[0], "yyyy-MM-dd", new Date())
    })
    setAllHolidays([...common, ...customHolidayList])
  }

  const [inProgress, setInProgress] = useState(false)

  const saveHoliday = (e) => {
    e.preventDefault()
    setInProgress(true)
    makeRequest("sp/add-holiday", {
      space_id,
      date: date ? format(date, "yyyy-MM-dd") : null,
      note,
    })
      .then(function (res) {
        setInProgress(false)
        toggle()
        toast.success("Details saved!")
        setNote("")
        setDate(null)
        if (res.customHolidays) {
          setCustomHolidays(res.customHolidays)
          handleAllHolidays(res.customHolidays, [])
        }
      })
      .catch((e) => {
        setInProgress(false)
        toast.error("Failed to save the details")
      })
  }

  const deleteHoliday = (e, id) => {
    e.preventDefault()
    setInProgress(true)
    makeRequest("sp/delete-holiday", {
      id,
    })
      .then(function (res) {
        if (res.customHolidays) {
          setCustomHolidays(res.customHolidays)
          handleAllHolidays(res.customHolidays, [])
        }
        setInProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setInProgress(false)
        toast.error("Failed to save the details")
      })
  }

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  useEffect(() => {
    setModal(!!date)
  }, [date])

  return (
    <React.Fragment>
      <Section title={"Out-of-Office Dates"} first>
        <Row>
          <Col lg={9}>
            <p>
              If you need to turn-off on-demand bookings for specific dates,
              please do it here.
              <br />
              Please note that all civic holidays are unavailable to book by
              default.
            </p>
          </Col>
          <Col md="6" lg={4} className={"exclude-weekends weekends-excluded"}>
            <Datepicker
              inline
              selected={date}
              minDate={new Date()}
              onSelect={(date) => setDate(date)}
              excludeDates={allHolidays}
            />
            <Modal isOpen={modal} toggle={toggle} className={""}>
              <ModalHeader toggle={toggle}>
                Exclude Date: {date && format(date, "MMM dd, yyyy")}
              </ModalHeader>
              <ModalBody>
                <Input
                  label="Notes"
                  helpText={"E.g. Staff meeting"}
                  placeholder={"Type here"}
                  value={note}
                  onChange={setNote}
                  type={"text"}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>{" "}
                <Button
                  color="primary"
                  onClick={saveHoliday}
                  disabled={inProgress}
                >
                  Exclude Date
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Section>

      <Section title={"Upcoming Out-of-Office dates "}>
        <Row>
          <Col lg="12">
            {customHolidays && customHolidays.length > 0 ? (
              <table className="table table-sm table-borderless table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {customHolidays.map(({ id, note, date }) => {
                    return (
                      <tr key={id}>
                        <td>
                          {date && date.split("T").length > 0
                            ? date.split("T")[0]
                            : "Invalid Date"}
                        </td>
                        <td>{note}</td>
                        <td>
                          <a
                            href={"#"}
                            className={"text-danger py-0 px-0"}
                            onClick={(e) => deleteHoliday(e, id)}
                          >
                            Remove
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <p>No Out-of-Office Dates found.</p>
            )}
          </Col>
        </Row>
      </Section>
    </React.Fragment>
  )
}

export default OutOfOfficeDates
