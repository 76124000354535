import React from "react"
import EditSpace from "@templates/EditSpace"
import SEO from "@components/seo"

const LocationPage = (props) => {
  return (
    <>
      <SEO title="Edit Location" />
      <EditSpace spaceId={props.params.id} />
    </>
  )
}

export default LocationPage
