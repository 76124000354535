import React, { useState } from "react"
import NumericInput from "react-numeric-input"
import { Badge, Col, FormGroup, Label, Row, Tooltip } from "reactstrap"

import "./CounterInput.less"

const CounterInput = ({
  label,
  name,
  className = "",
  onChange = () => null,
  min = null,
  max = null,
  value,
  helpText = null,
  fullWidth = false,
}) => {
  const [tooltip, setTooltip] = useState(false)

  return (
    <FormGroup className={className}>
      <Row>
        {label && (
          <Col
            xs={fullWidth ? 12 : 6}
            sm={fullWidth ? 12 : 7}
            className={"d-flex align-items-center"}
          >
            <Label for={name} className={"my-0"}>
              {label}{" "}
              {helpText && (
                <React.Fragment>
                  <Badge
                    color="secondary"
                    id={`tooltip_${name}`}
                    style={{
                      height: "25px",
                      width: "25px",
                      lineHeight: "25px",
                      padding: "0px",
                      borderRadius: "50%",
                    }}
                  >
                    ?
                  </Badge>
                  <Tooltip
                    placement="right"
                    isOpen={tooltip}
                    target={`tooltip_${name}`}
                    toggle={() => setTooltip(!tooltip)}
                  >
                    {helpText}
                  </Tooltip>
                </React.Fragment>
              )}
            </Label>
          </Col>
        )}
        <Col
          xs={fullWidth ? 12 : 6}
          sm={fullWidth ? 12 : 5}
          className={!fullWidth && label ? "text-right" : "mt-2"}
        >
          <NumericInput
            mobile
            min={min}
            max={max}
            className="form-control"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}

export default CounterInput
