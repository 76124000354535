import React, { useState } from "react"
import get from "lodash/get"
import Input from "../../fields/Input"
import Section from "../../fields/Section"
import { useAuth } from "@auth/useAuth"
import {
  Button,
  Col,
  Input as ReactStrapInput,
  InputGroup,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"

const Tax = ({ formData, refresh = () => null }) => {
  const { makeRequest } = useAuth()

  const [tax_percentage, setTaxPercentage] = useState(
    get(formData, "tax_percentage", "")
  )
  const [tax_label, setTaxLabel] = useState(get(formData, "tax_label", ""))

  const updateTac = async () => {
    if (!formData.id) return
    const data = {
      id: formData.id,
      data: { tax_percentage, tax_label },
    }
    const response = await makeRequest("sp/update", data)
    toast.success("Updated tax details.")
    refresh()
  }

  return (
    <>
      <Section title={"Tax Data"}>
        <Row>
          <Col md="6" ld={5}>
            <Input
              type="text"
              label="Tax Label"
              value={tax_label}
              placeholder={"This name will be displayed in the website."}
              onChange={(value) => setTaxLabel(value)}
            />
          </Col>
          <Col md="4" lg={3} xl={2}>
            <label htmlFor="" className="form-label">
              Tax percentage
            </label>
            <InputGroup>
              <ReactStrapInput
                min={0}
                max={50}
                type="number"
                step="0.001"
                label="Tax percentage"
                placeholder="ex: 0.13 for 13%"
                value={tax_percentage}
                onChange={(e) => {
                  setTaxPercentage(Number(e.target.value))
                }}
              />
            </InputGroup>
          </Col>
          <Col md="12">
            <Button color={"success"} onClick={updateTac}>
              Update
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default Tax
