import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import RadioBox from "../../fields/RadioSwitch"
import Section from "../../fields/Section"
import { toast } from "react-toastify"
import { useAuth } from "../../../../auth/useAuth"
import useLocationStore from "../../../../stores/useLocationStore"

const AutoBookings = ({ formData, refresh = () => null }) => {
  const { makeRequest } = useAuth()

  const autoBookings = useLocationStore(
    (state) => state.autoBookingsOfSelectedLocation
  )

  const [progress, setProgress] = useState(false)

  const [name, setName] = useState("")
  const [day, setDay] = useState("Monday")

  const handleDelete = async (id) => {
    setProgress(true)
    const data = {
      id: formData.id,
      data: {
        autoBookings: true,
        id,
      },
    }
    await makeRequest("sp/edit", data)
      .then((res) => {
        toast.success("Deleted.")
      })
      .catch((error) => {
        toast.error("Failed to delete.")
      })
    setProgress(false)
    refresh()
  }

  const addAutoBooking = async () => {
    setProgress(true)
    const data = {
      id: formData.id,
      data: {
        autoBookings: true,
        name,
        locationId: formData.id,
        day,
      },
    }
    await makeRequest("sp/edit", data)
      .then((res) => {
        toast.success("Added.")
      })
      .catch((error) => {
        console.log(error)
        toast.error("Failed to add.")
      })
    setProgress(false)
    refresh()
  }

  return (
    <div>
      <Section title={"Auto Booking"}>
        <Row>
          <Col md="6">
            <input
              type="text"
              className="form-control"
              placeholder={"Name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md="3">
            <select
              name="day"
              className="form-control"
              value={day}
              onChange={(e) => setDay(e.target.value)}
            >
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
            </select>
          </Col>
          <Col md="3">
            <button
              className="btn btn-primary btn-sm"
              disabled={progress || !name || !day}
              onClick={addAutoBooking}
            >
              Add
            </button>
          </Col>

          <Col sm={12}>
            <table className="table mt-3">
              <tbody>
                {autoBookings?.map((ab) => {
                  return (
                    <tr key={ab.id}>
                      <td>{ab.name}</td>
                      <td>on {ab.day}s</td>
                      <td>
                        <button
                          className="btn btn-text text-danger"
                          disabled={progress}
                          onClick={() => handleDelete(ab.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default AutoBookings
