import React, { useEffect, useState } from "react"
import * as styles from "../Assets/index.module.less"
import { Col, Row } from "reactstrap"
import PriceField from "../../../PriceField"
import { useAuth } from "@auth/useAuth"
import StyledInput from "../../../Input"
import DatePicker from "../../../DatePicker"
import CounterInput from "../../../CounterInput"
import get from "lodash/get"

const TeamDetails = ({ occupant = {}, updateOccupant = () => null }) => {
  const { user } = useAuth() || {}

  const [open, setOpen] = useState(false)

  useEffect(() => {
    const soId = get(user, "space_operator_id", "")
    if (!soId) return
    setOpen(soId === "21cb4ac5-336f-4a10-ab23-34ce713c3387")
  }, [user])

  return (
    <div className={styles.assets}>
      <button className={"btn btn-link"} onClick={() => setOpen(!open)}>
        Team Details
      </button>
      {open && <Details occupant={occupant} updateOccupant={updateOccupant} />}
    </div>
  )
}

export default TeamDetails

const Details = ({ occupant = {}, updateOccupant = () => null }) => {
  return (
    <div className={"mt-4"}>
      <Row>
        <Col sm={6}>
          <StyledInput
            label="Company Name"
            name="companyName"
            type="text"
            value={get(occupant, "company_name", "") || ""}
            onChange={(value) => updateOccupant("company_name", value)}
          />
        </Col>

        <Col sm={6}>
          <PriceField
            label="Actual price"
            name="actualPrice"
            value={get(occupant, "actual_price", "")}
            onChange={(value) => updateOccupant("actual_price", value)}
          />
        </Col>

        <Col sm={6}>
          <CounterInput
            label="Team Size"
            name="team_size"
            min={0}
            max={1000}
            value={get(occupant, "team_size", "") || 0}
            onChange={(value) => updateOccupant("team_size", value)}
            fullWidth
          />
        </Col>

        <Col sm={6}>
          <DatePicker
            withWeekends
            label="Contract Expiry Date"
            value={get(occupant, "contract_expiry_date", null) || null}
            onChange={(value) => updateOccupant("contract_expiry_date", value)}
            className={"mb-4"}
          />
        </Col>
        <Col sm={12}>
          <StyledInput
            label="Additional Notes"
            name="notes"
            type="textarea"
            className={"mb-0"}
            value={get(occupant, "notes", "") || ""}
            onChange={(value) => updateOccupant("notes", value)}
          />
        </Col>
      </Row>
    </div>
  )
}
