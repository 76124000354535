import React, { useState } from "react"
import { Button } from "reactstrap"
import { useAuth } from "../../../../../../auth/useAuth"
import RadioSwitch from "../../../../fields/RadioSwitch"
import { toast } from "react-toastify"

const Menu = ({ data, refresh }) => {
  const [name, setName] = useState("")
  const { makeRequest } = useAuth() || {}

  const [progress, setProgress] = useState(false)

  const save = async () => {
    setProgress(true)
    await makeRequest("menu", { id: null, data: { name, spaceId: data?.id } })
    await refresh(true)
    setProgress(false)
  }

  return (
    <div className={"my-5"}>
      <h4>Menu</h4>
      {data?.menus
        ?.sort(function (a, b) {
          return a.order - b.order
        })
        .map((menu) => {
          return <MainMenu menu={menu} key={menu.id} refresh={refresh} />
        })}

      <hr />
      <div className="d-flex align-items-center">
        <input
          type="text"
          placeholder={"Menu name"}
          className={"form-control mr-3 w-50"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          color={"light"}
          size={"sm"}
          onClick={save}
          disabled={progress || !name}
          className={"text-nowrap"}
        >
          + Add new menu
        </Button>
      </div>
    </div>
  )
}

export default Menu

const MainMenu = ({ menu, refresh }) => {
  const [menuName, setMenuName] = useState(menu?.name || "")
  const [menuOrder, setMenuOrder] = useState(menu?.order ?? 1)

  const [name, setName] = useState("")
  const [price, setPrice] = useState(0)
  const [description, setDescription] = useState("")
  const [enabled, setEnabled] = useState(true)

  const { makeRequest } = useAuth() || {}

  const [progress, setProgress] = useState(false)

  const save = async () => {
    setProgress(true)
    await makeRequest("menu", {
      id: menu?.id,
      data: { name: menuName, order: Number(Math.ceil(menuOrder)) },
    })
    setProgress(false)
  }

  const deleteMenu = async () => {
    let res = window.confirm(
      `You are about to delete the menu - ${menu.name}. Continue?`
    )
    if (!res) {
      return
    }
    setProgress(true)
    await makeRequest("menu", { id: menu?.id }, "DELETE")
    refresh(true)
  }

  const addNewItem = async () => {
    setProgress(true)
    await makeRequest("menu-item", {
      id: null,
      data: {
        name,
        price: Math.round(price * 100),
        description,
        menuId: menu?.id,
      },
    })
    await refresh(true)
    setName("")
    setDescription("")
    setPrice("")
    setEnabled(true)
    setProgress(false)
  }

  return (
    <div className={"my-5"}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5 className={"text-nowrap m-0"}>Menu Name: </h5>
        <input
          type="text"
          className={"form-control mx-3"}
          value={menuName}
          onChange={(e) => setMenuName(e.target.value)}
        />
        <h5 className={"text-nowrap m-0"}>Order: </h5>
        <input
          type="number"
          className={"form-control mx-3 w-25"}
          placeholder={"Order"}
          min={1}
          max={1000}
          step={1}
          value={menuOrder}
          onChange={(e) => setMenuOrder(e.target.value)}
        />
        <Button
          color={"success"}
          size={"sm"}
          onClick={save}
          disabled={progress}
        >
          Save
        </Button>
        <Button
          color={"danger"}
          size={"sm"}
          onClick={deleteMenu}
          outline
          disabled={progress}
          className={"ml-3"}
        >
          Delete
        </Button>
      </div>
      <table className="table table-sm table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Description</th>
            <th className={"text-center"}>Enabled</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {menu?.menuItems?.map((menuItem) => {
            return (
              <MenuItem
                menuItem={menuItem}
                key={menuItem.id}
                refresh={refresh}
              />
            )
          })}
          <tr>
            <td>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                className="form-control"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
            <td>
              <RadioSwitch
                onChange={() => {
                  setEnabled(!enabled)
                }}
                value={enabled}
              />
            </td>
            <td className={"text-right"}>
              <Button
                color={"light"}
                size={"sm"}
                onClick={addNewItem}
                disabled={progress || !name || !price}
              >
                + Add new menu item
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const MenuItem = ({ menuItem, refresh }) => {
  const [name, setName] = useState(menuItem?.name || "")
  const [price, setPrice] = useState(
    menuItem?.price ? menuItem?.price / 100 : 0
  )
  const [description, setDescription] = useState(menuItem?.description || "")
  const [enabled, setEnabled] = useState(menuItem?.isAvailable || false)

  const { makeRequest } = useAuth() || {}

  const [progress, setProgress] = useState(false)

  const save = async () => {
    setProgress(true)
    await makeRequest("menu-item", {
      id: menuItem?.id,
      data: { name, price: Math.round(price * 100), description },
    })
    setProgress(false)
    toast.success(`Updated - ${name}`)
  }

  const deleteItem = async () => {
    let res = window.confirm(
      `You are about to delete the menu item - ${menuItem.name}. Continue?`
    )
    if (!res) {
      return
    }
    setProgress(true)
    await makeRequest(
      "menu-item",
      {
        id: menuItem?.id,
      },
      "DELETE"
    )
    await refresh(true)
    setProgress(false)
  }

  return (
    <tr>
      <td>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </td>
      <td>
        <input
          type="number"
          className="form-control"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </td>
      <td>
        <RadioSwitch
          onChange={() => {
            setEnabled(!enabled)
          }}
          value={enabled}
        />
      </td>
      <td className={"text-right"}>
        <Button
          color={"success"}
          outline
          size={"sm"}
          onClick={save}
          disabled={progress}
        >
          Save
        </Button>
        <Button
          color={"danger"}
          outline
          size={"sm"}
          onClick={deleteItem}
          disabled={progress}
          className={"ml-3"}
        >
          Delete
        </Button>
      </td>
    </tr>
  )
}
